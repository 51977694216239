import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import ContactStepper from './ContactStepper';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import { addContact, updateContact, handleErrorWithSpinner, modalClosed } from '../redux/actions';
import './ContactModal.scss';

const ContactModal = ({ modalVisibility, modalType }) => {
  const dispatch = useDispatch();
  const contact = useSelector(userSelectedVariables.getContact);
  const pvendor = useSelector(userSelectedVariables.getPvendorNumber);
  const mvendor = useSelector(userSelectedVariables.getSelectedMvendor);

  const save = modalType === 'ADD' ? addContact : updateContact;

  return (
    <Modal
      keyboard
      className="contact-modal"
      zIndex={999}
      width={1200}
      footer={null}
      visible={modalVisibility}
      wrapProps={{
        'data-testid': 'contact-modal',
      }}
      destroyOnClose
      onCancel={() => {
        dispatch(modalClosed());
      }}
    >
      <ContactStepper
        onDone={async () => {
          await dispatch(handleErrorWithSpinner(
            save(pvendor, mvendor.number, mvendor.departmentNumber, contact)
          ));
        }}
        modalType={modalType}
      />
    </Modal>
  );
};

export default ContactModal;
