import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import * as constants from '../redux/selectors/constants';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import * as presentation from '../redux/selectors/presentation';
import { setContactMarketAssignments, setContactByoAssignments } from '../redux/actions';
import ContactAssignmentLocationTable from './ContactAssignmentLocationTable';
import './ContactAssignmentByoMarketTable.scss';

const ContactAssignmentByoMarketTable = ({ marketCheckbox, byoCheckbox }) => {
  const dispatch = useDispatch();
  const byoDataSource = useSelector(constants.getContactAssignmentByoTableEntries);
  const byoMarkets = useSelector(constants.getContactAssignmentByoMarketTableEntries);
  const getByoForMarketFunction = useSelector(constants.getByoForMarketFunction);
  const [selectedByo, setSelectedByo] = useState();
  const checkedByos = useSelector(userSelectedVariables.getContactByoAssignments);
  const checkedMarkets = useSelector(userSelectedVariables.getContactMarketAssignments);
  const { ASSIGNMENT_ERROR: assignmentError } = useSelector(presentation.getErrors);

  const getMarketsByByoNumber = (byoNumber) => _.chain(byoMarkets)
    .get(byoNumber)
    .map('key')
    .value();

  const onCheckedMarketsChange = (selectedRowKeys) => {
    const currentByoMarkets = getMarketsByByoNumber(selectedByo);
    const allCheckedMarketsWithoutCurrent = _.difference(checkedMarkets, currentByoMarkets);

    dispatch(setContactMarketAssignments(
      _.concat(allCheckedMarketsWithoutCurrent, selectedRowKeys)
    ));
  };

  const getCurrentCheckedMarkets = () => (
    _.intersection(getMarketsByByoNumber(selectedByo), checkedMarkets)
  );

  const byosWithCheckedMarkets = () => _.chain(checkedMarkets)
    .map(getByoForMarketFunction)
    .map('byoNbr')
    .uniq()
    .value();

  const onCheckedByosChange = (selectedRowKeys) => {
    dispatch(setContactByoAssignments(selectedRowKeys));
  };

  return (
    <>
      <div className={classNames('container', { 'red-border': assignmentError })}>
        <div className="byo-market-table">
          <ContactAssignmentLocationTable
            title="BYO"
            dataSource={byoDataSource}
            showCheckboxes={byoCheckbox}
            checkedKeys={checkedByos}
            onChange={onCheckedByosChange}
            onRowClick={(row) => setSelectedByo(row.key)}
            data-testid="byo-table"
            boldRows={byosWithCheckedMarkets()}
          />
        </div>
        <div className="byo-market-table">
          <ContactAssignmentLocationTable
            title="Market"
            dataSource={_.get(byoMarkets, selectedByo, [])}
            showCheckboxes={marketCheckbox}
            checkedKeys={getCurrentCheckedMarkets()}
            onChange={onCheckedMarketsChange}
            data-testid="market-table"
          />
        </div>
      </div>
      <p className="assignment-error-message">
        { assignmentError }
      </p>
    </>
  );
};
export default ContactAssignmentByoMarketTable;
