import _ from 'lodash';
import {
  SET_ACTIVE_PVENDOR,
  CLEAR_USER_SELECTED_VARIABLES,
  SET_SELECTED_MVENDOR_NUMBER,
  SET_COUNTRY_STATES,
  CHANGE_DELETE_ASSIGNMENTS,
  EDIT_CONTACT_INIT,
  SUBMIT_ADD_CONTACT_PROFILE,
  SUBMIT_EDIT_CONTACT_PROFILE,
  CLEAR_CONTACT,
  SET_CONTACT_CORPORATE_ASSIGNMENT,
  SET_CONTACT_CORPORATE_UNASSIGNMENT,
  SET_CONTACT_BYO_ASSIGNMENTS,
  SET_CONTACT_MARKET_ASSIGNMENTS,
  SET_CONTACT_DC_ASSIGNMENTS,
  MODAL_CLOSED,
  DELETE_CONTACT_INIT,
} from '../actions/index';
import initialState from '../initialState';

const EMPTY_LOCATION_ASSIGNMENTS = {
  companies: [],
  byos: [],
  markets: [],
  dcs: [],
};

function userSelectedVariables(state = initialState.userSelectedVariables, action) {
  switch (action.type) {
    case CLEAR_USER_SELECTED_VARIABLES: {
      return {};
    }
    case SET_ACTIVE_PVENDOR: {
      const { activePvendor } = action;
      return { ...state, activePvendor };
    }
    case SET_SELECTED_MVENDOR_NUMBER: {
      const { selectedMvendorNumber } = action;
      return { ...state, selectedMvendorNumber };
    }
    case SET_COUNTRY_STATES: {
      const { countryStates, countryCode } = action;
      return {
        ...state,
        countryStates: {
          ...state.countryStates,
          [countryCode]: countryStates,
        },
      };
    }
    case DELETE_CONTACT_INIT: {
      const { contactProfile, locationAssignment } = action;
      return {
        ...state,
        contact: {
          ...contactProfile,
          ...EMPTY_LOCATION_ASSIGNMENTS,
          ...locationAssignment,
        },
        locationToDelete: locationAssignment,
        originalLocationAssignments: _.pick(contactProfile, ['byos', 'markets', 'dcs']),
      };
    }
    case CHANGE_DELETE_ASSIGNMENTS: {
      const { contactProfile, deleteAll } = action;
      return {
        ...state,
        contact: {
          ...contactProfile,
          ...(
            deleteAll
              ? state.originalLocationAssignments
              : state.locationToDelete
          ),
        },
      };
    }

    case EDIT_CONTACT_INIT: {
      const { contact, locationAssignment } = action;
      return {
        ...state,
        contact,
        locationToEdit: locationAssignment,
        originalLocationAssignments: _.pick(contact, ['companies', 'byos', 'markets', 'dcs']),
      };
    }
    case SUBMIT_EDIT_CONTACT_PROFILE: {
      const { contactProfile, editAllLocations } = action;

      return {
        ...state,
        contact: {
          ...contactProfile,
          ...EMPTY_LOCATION_ASSIGNMENTS,
          ...(
            editAllLocations
              ? state.originalLocationAssignments
              : state.locationToEdit
          ),
        },
      };
    }
    case SUBMIT_ADD_CONTACT_PROFILE: {
      const { contactProfile } = action;
      return {
        ...state,
        contact: {
          ...contactProfile,
          ...(_.pick(state.contact, ['companies', 'byos', 'markets', 'dcs'])),
        },
      };
    }
    case SET_CONTACT_CORPORATE_ASSIGNMENT: {
      return {
        ...state,
        contact: {
          ...state.contact,
          companies: ['1'],
        },
      };
    }
    case SET_CONTACT_CORPORATE_UNASSIGNMENT: {
      return {
        ...state,
        contact: {
          ...state.contact,
          companies: [],
        },
      };
    }
    case SET_CONTACT_BYO_ASSIGNMENTS: {
      const { byos } = action;
      return {
        ...state,
        contact: {
          ...state.contact,
          byos,
        },
      };
    }
    case SET_CONTACT_MARKET_ASSIGNMENTS: {
      const { markets } = action;
      return {
        ...state,
        contact: {
          ...state.contact,
          markets,
        },
      };
    }
    case SET_CONTACT_DC_ASSIGNMENTS: {
      const { dcs } = action;
      return {
        ...state,
        contact: {
          ...state.contact,
          dcs,
        },
      };
    }
    case MODAL_CLOSED: case CLEAR_CONTACT: {
      return {
        ...state, contact: {},
      };
    }
    default: {
      return state;
    }
  }
}

export default userSelectedVariables;
