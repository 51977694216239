import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import Spinner from '../components/Spinner';
import AppNavigator from './AppNavigator';
import ErrorPage from './ErrorPage';
import * as presentation from '../redux/selectors/presentation';
import * as userProfile from '../redux/selectors/userProfile';
import * as actions from '../redux/actions';

const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(presentation.isLoading);
  const isAppInitStart = useSelector(presentation.isAppInitStart);
  const { GENERIC: error } = useSelector(presentation.getErrors);
  const isUserAuthenticated = useSelector(userProfile.isUserAuthenticated);

  useEffect(() => {
    if (!isAppInitStart) {
      dispatch(actions.handleErrorWithSpinner(actions.appInit()));
    }
  }, [dispatch, isAppInitStart]);

  return (
    <div className="App">
      {isLoading && <Spinner />}
      {(isUserAuthenticated && <AppNavigator />) || (error && <ErrorPage />)}
    </div>
  );
};

export default App;
