import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import ErrorIcon from '../components/ErrorIcon';
import { refresh } from '../redux/actions';
import './ErrorPage.scss';

const ErrorPage = () => {
  const dispatch = useDispatch();

  return (
    <div data-testid="error-page" className="error-page">
      <ErrorIcon data-testid="error-image" />
      <h2>Oops, Something went wrong</h2>
      <p>We&apos;re working on this issue, but click below to refresh your browser :)</p>
      <div>
        <Button onClick={() => dispatch(refresh())}>
          Refresh
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
