import _ from 'lodash';
import ApiError from '../errors/ApiError';
import { INVALID_LOCATION_DISPLAY_NAME } from './constants';

export const generateByoContactTableEntry = (contact, byo) => ({
  key: `byo${byo.byoNbr}-${contact.addressNumber}`,
  id: _.get(contact, 'addressNumber'),
  location: `${byo.byoNbr} - ${byo.byoDesc || INVALID_LOCATION_DISPLAY_NAME}`,
  name: _.get(contact, 'contactName', ''),
  byoNumber: byo.byoNbr,
  phone: _.get(contact, 'phoneNumber', ''),
  email: _.isEmpty(contact) ? '' : _.get(contact, 'electronicMailAddressText') || 'Not on file',
  country: _.get(contact, 'countryName', ''),
});

export const generateMarketContactTableEntry = (contact, market) => ({
  key: `market${market.marketNbr}-${contact.addressNumber}`,
  id: _.get(contact, 'addressNumber'),
  location: `${market.marketNbr} - ${market.marketDesc || INVALID_LOCATION_DISPLAY_NAME}`,
  name: contact.contactName,
  marketNumber: market.marketNbr,
  phone: contact.phoneNumber,
  email: _.get(contact, 'electronicMailAddressText') || 'Not on file',
  country: contact.countryName,
});

export const generateDcContactTableEntry = (contact, dc) => ({
  key: `dc${dc.distributionCenterNumber}-${contact.addressNumber}`,
  id: _.get(contact, 'addressNumber'),
  location: `${dc.distributionCenterNumber} - ${dc.distributionCenterName || INVALID_LOCATION_DISPLAY_NAME}`,
  name: contact.contactName,
  dcNumber: dc.distributionCenterNumber,
  phone: contact.phoneNumber,
  email: _.get(contact, 'electronicMailAddressText') || 'Not on file',
  country: contact.countryName,
});

export const generateCorporateContactTableEntry = (contact) => ({
  key: contact.addressNumber,
  id: _.get(contact, 'addressNumber'),
  location: 'Corporate',
  name: contact.contactName,
  phone: contact.phoneNumber,
  email: _.get(contact, 'electronicMailAddressText') || 'Not on file',
  country: contact.countryName,
});

export const generateMvendorStatus = (statusCode) => {
  let mvendorStatus = '';
  switch (statusCode) {
    case '100':
      mvendorStatus = 'ACTIVE';
      break;
    case '400':
      mvendorStatus = 'INACTIVE';
      break;
    case '600':
      mvendorStatus = 'DELETE';
      break;
    default:
      mvendorStatus = statusCode || 'N/A';
  }
  return mvendorStatus;
};

export const fetchErrorHandler = (
  errorMessage = 'Fetch Error Handler',
  isErrorStatus = (status) => status >= 400
) => (res) => {
  if (isErrorStatus(res.status)) {
    throw new ApiError(
      res.status,
      `${errorMessage} (HTTP Status ${res.status})`
    );
  }
  return res;
};
