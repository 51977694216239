import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { fetchStates } from '../redux/actions';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';

export const useCountryStates = (initialCountryCode) => {
  const [countryCode, setCountryCode] = useState(initialCountryCode);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStates(countryCode));
  }, [countryCode, dispatch]);

  const countryStates = useSelector(userSelectedVariables.getCountryStates);

  return {
    states: _.get(countryStates, countryCode, []),
    setCountryCode,
  };
};
