import React from 'react';
import Icon from '@ant-design/icons';

const EditIconSvg = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Edit Icon</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M0,15.800462 L0,20 L4.03433874,20 L15.8491879,7.70135314 L11.8148492,3.50181518 L0,15.800462 Z M18.9229698,4.50170517 C19.3100108,4.0445264 19.3100108,3.35905996 18.9229698,2.90188119 L18.9229698,2.90188119 L16.425522,0.302167217 C15.9863275,-0.100722406 15.3278256,-0.100722406 14.8886311,0.302167217 L14.8886311,0.302167217 L12.9675174,2.3019472 L17.0018561,6.50148515 C16.9058004,6.50148515 18.9229698,4.50170517 18.9229698,4.50170517 Z" fill="#FA6302" fillRule="nonzero" />
    </g>
  </svg>
);

const EditIcon = ({ onClick }) => (
  <Icon component={EditIconSvg} onClick={onClick} />
);

export default EditIcon;
