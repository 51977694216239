import React from 'react';
import Icon from '@ant-design/icons';

const DeleteIconSvg = () => (
  <svg width="16px" height="20px" viewBox="0 0 16 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Delete Icon</title>
    <g id="Delete-Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M1.1,17.8 C1.11085716,19.0105007 2.08949926,19.9891428 3.3,20 L12.2,20 C13.4105007,19.9891428 14.3891428,19.0105007 14.4,17.8 L14.4,4.4 L1.1,4.4 L1.1,17.8 Z M15.6,1.1 L11.7,1.1 L10.6,0 L5,0 L3.9,1.1 L0,1.1 L0,3.3 L15.6,3.3 L15.6,1.1 Z" id="path-1" fill="#F96301" fillRule="nonzero" />
    </g>
  </svg>
);

const DeleteIcon = ({ onClick }) => (
  <Icon component={DeleteIconSvg} onClick={onClick} />
);

export default DeleteIcon;
