import { SET_ENVIRONMENT } from '../actions';
import initialState from '../initialState';

function environment(state = initialState.environment, action) {
  switch (action.type) {
    case SET_ENVIRONMENT:
      return { ...state, ...action.environment };
    default:
      return state;
  }
}

export default environment;
