import React from 'react';
import _ from 'lodash';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ResultItem from '../components/ResultItem';
import { setSelectedMvendorNumber } from '../redux/actions';
import * as util from '../util/index';
import * as constants from '../redux/selectors/constants';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import './MvendorInfo.scss';

const MvendorInfo = () => {
  const dispatch = useDispatch();
  const selectedMvendor = useSelector(userSelectedVariables.getSelectedMvendor);
  const mvendorOptions = useSelector(userSelectedVariables.getMvendorSelectOptions);
  const hasMvendors = useSelector(userSelectedVariables.hasMvendors);
  const types = useSelector(constants.getVendorTypes);
  const subTypes = useSelector(constants.getVendorSubTypes);

  function handleSelect(value) {
    dispatch(setSelectedMvendorNumber(value));
  }

  return (
    <div className="section">
      <h2> MVendor </h2>
      { hasMvendors ? (
        <div className="mvendor-grid">
          <div className="mvendor-select">
            <Select placeholder="Select" style={{ width: 150 }} options={mvendorOptions} onSelect={(value) => handleSelect(value)} />
          </div>
          {!_.isEmpty(selectedMvendor) && (
            <>
              <ResultItem header="MVendor Name">{selectedMvendor.name}</ResultItem>
              <ResultItem header="Department">{selectedMvendor.departmentNumber}</ResultItem>
              <ResultItem
                header="Category"
              >
                {_.get(subTypes, `${selectedMvendor.subordinateTypeCode}.typeDescription`)}
              </ResultItem>
              <ResultItem
                header="Type"
              >
                {_.get(types, `${selectedMvendor.typeCode}.merchandiseVendorTypeDescription`)}
              </ResultItem>
              <ResultItem header="Status">{util.generateMvendorStatus(selectedMvendor.statusCode)}</ResultItem>
            </>
          )}
        </div>
      ) : (
        <div className="mvendor-blank margin-left">
          <p> There are no MVendors for this Pvendor </p>
        </div>
      )}
    </div>
  );
};

export default MvendorInfo;
