import _, { parseInt } from 'lodash';
import fp from 'lodash/fp';
import { createSelector } from 'reselect';
import {
  generateByoContactTableEntry,
  generateMarketContactTableEntry,
  generateDcContactTableEntry,
} from '../../util';
import * as constants from './constants';
import * as environment from './environment';
import * as userSelectedVariables from './userSelectedVariables';
import * as userProfile from './userProfile';

export const isLoginDataLoaded = createSelector(
  environment.isLoginUrlLoaded,
  userProfile.isAuthenticationCheckComplete,
  fp.rest(fp.every(fp.eq(true)))
);

const findDc = fp.curry((dcs, distributionCenterNumber) =>
  _.find(dcs, { distributionCenterNumber }) || { distributionCenterNumber });

export const getDcTableData = createSelector(
  constants.getDcs,
  userSelectedVariables.getSelectedMvendorContactsAssignedToDcs,
  (dcs, contacts) =>
    _.chain(contacts)
      .flatMap((contact) =>
        _.chain(contact.dcs)
          .map(findDc(dcs))
          .map((dc) => generateDcContactTableEntry(contact, dc))
          .value())
      .sortBy((entry) => parseInt(entry.dcNumber, 10))
      .value()
);

export const getByoMarketTableData = createSelector(
  constants.getByos,
  constants.getMarkets,
  constants.getByoForMarketFunction,
  userSelectedVariables.getSelectedMvendorContactsAssignedToByos,
  userSelectedVariables.getSelectedMvendorContactsAssignedToMarkets,
  (byos, markets, getByoForMarket, byoContacts, marketContacts) => {
    const byoContactTableEntries = [];
    const marketContactTableEntries = [];

    // create all byo entries
    _.forEach(byoContacts, (byoContact) => {
      const byosForContact = _.map(byoContact.byos, (byoNbr) => (
        _.find(byos, { byoNbr }) || { byoNbr }
      ));

      _.forEach(byosForContact, (byo) => {
        byoContactTableEntries.push(
          generateByoContactTableEntry(byoContact, byo)
        );
      });
    });

    // create all market entries
    _.forEach(marketContacts, (marketContact) => {
      const marketsForContact = _.map(marketContact.markets, (marketNbr) => (
        _.find(markets, { marketNbr }) || { marketNbr }
      ));

      _.forEach(marketsForContact, (market) => {
        marketContactTableEntries.push(
          generateMarketContactTableEntry(marketContact, market)
        );
      });
    });

    // assign all market entries to byo entries (creating byo entries if necessary)
    _.forEach(marketContactTableEntries, (marketContactTableEntry) => {
      const byoForMarket = getByoForMarket(marketContactTableEntry.marketNumber) || { byoNbr: 0 };

      if (byoForMarket) {
        // get all byo table entries for byo associated w/ market of current contact
        const byoContactTableEntriesForMarket = _.filter(
          byoContactTableEntries,
          { byoNumber: byoForMarket.byoNbr }
        );

        // if byo entry was not created yet create empty one and add market entry
        if (_.isEmpty(byoContactTableEntriesForMarket)) {
          const byoContactTableEntryForMarket = generateByoContactTableEntry(
            {},
            byoForMarket
          );
          byoContactTableEntryForMarket.children = [marketContactTableEntry];
          byoContactTableEntries.push(byoContactTableEntryForMarket);
          // else add market entry to every existing byo entry for byo that market entry belongs to
          // (this can result in one market enty being attached to multiple byo entries)
        } else {
          _.forEach(
            byoContactTableEntriesForMarket,
            (byoContactTableEntryForMarket) => {
              if (!byoContactTableEntryForMarket.children) {
                // eslint-disable-next-line no-param-reassign
                byoContactTableEntryForMarket.children = [];
              }
              byoContactTableEntryForMarket.children.push(
                marketContactTableEntry
              );
            }
          );
        }
      }
    });

    // sort market and byo entries
    _.forEach(byoContactTableEntries, (e) => {
      if (e.children) {
        e.children = _.sortBy(e.children, (child) => parseInt(child.marketNumber, 10));
      }
    });

    return _.sortBy(byoContactTableEntries, (entry) => parseInt(entry.byoNumber, 10));
  }
);
