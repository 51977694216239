import _ from 'lodash';
import fp from 'lodash/fp';
import { createSelector } from 'reselect';
import { generateCorporateContactTableEntry } from '../../util';
import { getByos, getMarkets, getDcs } from './constants';
import { INVALID_LOCATION_DISPLAY_NAME } from '../../util/constants';

export const getActivePvendor = fp.get('userSelectedVariables.activePvendor');
export const getSelectedMvendorNumber = fp.get('userSelectedVariables.selectedMvendorNumber');
export const getCountryStates = fp.get('userSelectedVariables.countryStates');
export const getContact = fp.get('userSelectedVariables.contact');
export const getOriginalLocationAssignments = fp.get('userSelectedVariables.originalLocationAssignments');

export const getPvendorNumber = createSelector(
  getActivePvendor,
  fp.get('pvendorNumber')
);
export const getActiveMvendors = createSelector(
  getActivePvendor,
  fp.get('mvendors')
);
export const isPvendorActive = createSelector(
  getActivePvendor,
  fp.negate(fp.isEmpty)
);
export const getSelectedMvendor = createSelector(
  getActiveMvendors,
  getSelectedMvendorNumber,
  (vendors, number) => _.find(vendors, { number })
);

export const getPvendorName = createSelector(
  getActivePvendor,
  fp.get('pvendorName')
);

export const getPartnerTypes = createSelector(
  getActivePvendor,
  fp.flow(fp.get('partnerTypes'), fp.map(fp.get('description')))
);

export const getSelectedMvendorContacts = createSelector(
  getSelectedMvendor,
  fp.get('contacts')
);

export const getSelectedMvendorContactsAssignedToByos = createSelector(
  getSelectedMvendorContacts,
  fp.filter(fp.flow(fp.get('byos'), fp.negate(fp.isEmpty)))
);

export const getSelectedMvendorContactsAssignedToMarkets = createSelector(
  getSelectedMvendorContacts,
  fp.filter(fp.flow(fp.get('markets'), fp.negate(fp.isEmpty)))
);

export const getSelectedMvendorContactsAssignedToDcs = createSelector(
  getSelectedMvendorContacts,
  fp.filter(fp.flow(fp.get('dcs'), fp.negate(fp.isEmpty)))
);

export const getSelectedMvendorCorporateContacts = createSelector(
  getSelectedMvendorContacts,
  fp.filter(fp.flow(fp.get('companies'), fp.includes('1')))
);

export const getCorporateTableData = createSelector(
  getSelectedMvendorCorporateContacts,
  fp.map(generateCorporateContactTableEntry)
);

export const getMvendorSelectOptions = createSelector(
  getActiveMvendors,
  fp.map((mvendor) => ({
    value: mvendor.number,
    label: mvendor.number,
  }))
);

export const hasMvendors = createSelector(
  getActiveMvendors,
  fp.negate(fp.isEmpty)
);

export const isCorporateAssigned = createSelector(
  getContact,
  fp.flow(fp.get('companies'), fp.includes('1'))
);

export const getContactByoAssignments = createSelector(
  getContact,
  fp.get('byos')
);

export const getContactMarketAssignments = createSelector(
  getContact,
  fp.get('markets')
);

export const getContactDcAssignments = createSelector(
  getContact,
  fp.get('dcs')
);

export const getByoMapping = createSelector(
  getContactByoAssignments,
  getByos,
  (contactByos, byos) => _.chain(contactByos)
    .map((byoNbr) => _.find(byos, { byoNbr }) || { byoNbr })
    .value()
);

const generateSummaryByoTableEntry = (byo) => ({
  key: _.parseInt(byo.byoNbr),
  label: `${byo.byoNbr} - ${byo.byoDesc || INVALID_LOCATION_DISPLAY_NAME}`,
});

export const getSummaryByoTableEntries = createSelector(
  getByoMapping,
  fp.flow(fp.map(generateSummaryByoTableEntry), fp.sortBy('key'))
);

export const getMarketMapping = createSelector(
  getContactMarketAssignments,
  getMarkets,
  (contactMarkets, markets) => _.chain(contactMarkets)
    .map((marketNbr) => _.find(markets, { marketNbr }) || { marketNbr })
    .value()
);

const generateSummaryMarketTableEntry = (market) => ({
  key: _.parseInt(market.marketNbr),
  label: `${market.marketNbr} - ${market.marketDesc || INVALID_LOCATION_DISPLAY_NAME}`,
});

export const getSummaryMarketTableEntries = createSelector(
  getMarketMapping,
  fp.flow(fp.map(generateSummaryMarketTableEntry), fp.sortBy('key'))
);

export const getDcMapping = createSelector(
  getContactDcAssignments,
  getDcs,
  (contactDcs, dcs) => _.chain(contactDcs)
    .map((distributionCenterNumber) => (
      _.find(dcs, { distributionCenterNumber }) || { distributionCenterNumber }
    ))
    .value()
);

const generateSummaryDcTableEntry = (dc) => ({
  key: _.parseInt(dc.distributionCenterNumber),
  label: `${dc.distributionCenterNumber} - ${dc.distributionCenterName || INVALID_LOCATION_DISPLAY_NAME}`,
});

export const getSummaryDcTableEntries = createSelector(
  getDcMapping,
  fp.flow(fp.map(generateSummaryDcTableEntry), fp.sortBy('key'))
);
