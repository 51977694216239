import React from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import Header from './Header';
import ViewContainer from '../components/ViewContainer';
import Main from './Main';
import ErrorPage from './ErrorPage';
import * as presentation from '../redux/selectors/presentation';

const { Content } = Layout;

const AppNavigator = () => {
  const { GENERIC: error } = useSelector(presentation.getErrors);

  return (
    <Layout>
      <Header />
      <Content>
        <ViewContainer>
          {error ? <ErrorPage /> : <Main />}
        </ViewContainer>
      </Content>
    </Layout>
  );
};

export default AppNavigator;
