import _ from 'lodash';
import api from '../../api';
import pvendorValidation from '../../util/inputValidation';
import { getCountryStates } from '../selectors/userSelectedVariables';
import { getEnvironment } from '../selectors/environment';
import ApiError from '../../errors/ApiError';

export const APP_INIT_START = 'APP_INIT_START';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_USER_SELECTED_VARIABLES = 'CLEAR_USER_SELECTED_VARIABLES';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const LOADING_SPINNER_ON = 'LOADING_SPINNER_ON';
export const LOADING_SPINNER_OFF = 'LOADING_SPINNER_OFF';
export const SET_ACTIVE_PVENDOR = 'SET_ACTIVE_PVENDOR';
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const SET_SELECTED_MVENDOR_NUMBER = 'SET_SELECTED_MVENDOR_NUMBER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_VENDOR_TYPES = 'SET_VENDOR_TYPES';
export const SET_VENDOR_SUB_TYPES = 'SET_VENDOR_SUB_TYPES';
export const SET_BYOS = 'SET_BYOS';
export const SET_DCS = 'SET_DCS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_COUNTRY_STATES = 'SET_COUNTRY_STATES';
export const CLEAR_COUNTRY_STATES = 'CLEAR_COUNTRY_STATES';
export const SET_DELETE_CONTACT_ASSIGNMENT = 'SET_DELETE_CONTACT_ASSIGNMENT';
export const CHANGE_DELETE_ASSIGNMENTS = 'CHANGE_DELETE_ASSIGNMENTS';
export const EDIT_CONTACT_INIT = 'EDIT_CONTACT_INIT';
export const ADD_CONTACT_INIT = 'ADD_CONTACT_INIT';
export const DELETE_CONTACT_INIT = 'DELETE_CONTACT_INIT';
export const SUBMIT_EDIT_CONTACT_PROFILE = 'SUBMIT_EDIT_CONTACT_PROFILE';
export const SUBMIT_ADD_CONTACT_PROFILE = 'SUBMIT_ADD_CONTACT_PROFILE';
export const CLEAR_CONTACT = 'CLEAR_CONTACT';
export const MODAL_CLOSED = 'MODAL_CLOSED';
export const SET_CONTACT_CORPORATE_ASSIGNMENT = 'SET_CONTACT_CORPORATE_ASSIGNMENT';
export const SET_CONTACT_CORPORATE_UNASSIGNMENT = 'SET_CONTACT_CORPORATE_UNASSIGNMENT';
export const SET_CONTACT_BYO_ASSIGNMENTS = 'SET_CONTACT_BYO_ASSIGNMENTS';
export const SET_CONTACT_MARKET_ASSIGNMENTS = 'SET_CONTACT_MARKET_ASSIGNMENTS';
export const SET_CONTACT_DC_ASSIGNMENTS = 'SET_CONTACT_DC_ASSIGNMENTS';
export const REFRESH = 'REFRESH';

export const appInitStart = () => ({
  type: APP_INIT_START,
});

export const clearError = (code) => ({
  type: CLEAR_ERROR,
  code,
});

export const clearUserSelectedVariables = () => ({
  type: CLEAR_USER_SELECTED_VARIABLES,
});

export const displayError = (error) => ({
  type: DISPLAY_ERROR,
  error,
});

export const loadingSpinnerOn = () => ({
  type: LOADING_SPINNER_ON,
});

export const loadingSpinnerOff = () => ({
  type: LOADING_SPINNER_OFF,
});

export const setActivePvendor = (activePvendor) => ({
  type: SET_ACTIVE_PVENDOR,
  activePvendor,
});

export const setEnvironment = (environment) => ({
  type: SET_ENVIRONMENT,
  environment,
});

export const setSelectedMvendorNumber = (selectedMvendorNumber) => ({
  type: SET_SELECTED_MVENDOR_NUMBER,
  selectedMvendorNumber,
});

export const setUserProfile = (userProfile) => ({
  type: SET_USER_PROFILE,
  userProfile,
});

export const setVendorTypes = (vendorTypes) => ({
  type: SET_VENDOR_TYPES,
  vendorTypes,
});

export const setVendorSubTypes = (vendorSubTypes) => ({
  type: SET_VENDOR_SUB_TYPES,
  vendorSubTypes,
});

export const setByos = (byos) => ({
  type: SET_BYOS,
  byos,
});

export const setDcs = (dcs) => ({
  type: SET_DCS,
  dcs,
});

export const setCountries = (countries) => ({
  type: SET_COUNTRIES,
  countries,
});

export const setCountryStates = (countryStates, countryCode) => ({
  type: SET_COUNTRY_STATES,
  countryStates,
  countryCode,
});

export const addContactInit = () => ({
  type: ADD_CONTACT_INIT,
});

export const editContactInit = (contact, locationAssignment) => ({
  type: EDIT_CONTACT_INIT,
  contact,
  locationAssignment,
});

export const deleteContactInit = (contactProfile, locationAssignment) => ({
  type: DELETE_CONTACT_INIT,
  contactProfile,
  locationAssignment,
});

export const setDeleteContactAssignment = (contactProfile, locationAssignment) => ({
  type: SET_DELETE_CONTACT_ASSIGNMENT,
  contactProfile,
  locationAssignment,
});

export const changeDeleteAssignments = (contactProfile, deleteAll) => ({
  type: CHANGE_DELETE_ASSIGNMENTS,
  contactProfile,
  deleteAll,
});

export const submitEditContactProfile = (contactProfile, editAllLocations) => ({
  type: SUBMIT_EDIT_CONTACT_PROFILE,
  contactProfile,
  editAllLocations,
});

export const submitAddContactProfile = (contactProfile) => ({
  type: SUBMIT_ADD_CONTACT_PROFILE,
  contactProfile,
});

export const setContactCorporateAssignment = () => ({
  type: SET_CONTACT_CORPORATE_ASSIGNMENT,
});

export const setContactCorporateUnassignment = () => ({
  type: SET_CONTACT_CORPORATE_UNASSIGNMENT,
});

export const setContactByoAssignments = (byos) => ({
  type: SET_CONTACT_BYO_ASSIGNMENTS,
  byos,
});

export const setContactMarketAssignments = (markets) => ({
  type: SET_CONTACT_MARKET_ASSIGNMENTS,
  markets,
});

export const setContactDcAssignments = (dcs) => ({
  type: SET_CONTACT_DC_ASSIGNMENTS,
  dcs,
});

export const clearContact = () => ({
  type: CLEAR_CONTACT,
});

export const modalClosed = () => ({
  type: MODAL_CLOSED,
});

export const refresh = () => ({
  type: REFRESH,
});

// redux-thunk
export const fetchEnvironment = () => async (dispatch, getState) => {
  let environment = getEnvironment(getState());
  if (_.isEmpty(environment)) {
    environment = await api.getEnvironment();
    dispatch(setEnvironment(environment));
  }
  return environment;
};

export const logout = () => async (dispatch) => {
  await api.deleteSession();

  const environment = await dispatch(fetchEnvironment());

  const disableLinks = 'resource_links_disabled=true';
  const disableReset = 'forgot_disabled=true';
  const appName = 'app_name_override=Store Contacts';
  const caller = 'calling_program=SC';
  const message = 'message=Welcome to Store Contacts';
  const redirect = `return_to=${window.location.origin}`;
  const queryParms = `${redirect}&${disableLinks}&${disableReset}&${appName}&${caller}&${message}`;
  window.location.replace(`${environment.loginUrl}?${queryParms}`);
};

const fetchVendorTypes = () => (dispatch) =>
  api.getVendorTypes()
    .then(setVendorTypes)
    .then(dispatch);

const fetchVendorSubTypes = () => (dispatch) =>
  api.getVendorSubTypes()
    .then(setVendorSubTypes)
    .then(dispatch);

const fetchByos = () => (dispatch) =>
  api.getByos()
    .then(setByos)
    .then(dispatch);

const fetchDcs = () => (dispatch) =>
  api.getDcs()
    .then(setDcs)
    .then(dispatch);

const fetchCountries = () => (dispatch) =>
  api.getCountries()
    .then(setCountries)
    .then(dispatch);

const fetchPvendor = (pvendorNumber) => (dispatch) =>
  api.getPvendor(pvendorNumber)
    .then(setActivePvendor)
    .then(dispatch);

export const appInit = () => async (dispatch) => {
  dispatch(appInitStart());

  const userProfile = await api.getUserProfile();
  dispatch(setUserProfile(userProfile));

  await Promise.all([
    dispatch(fetchVendorTypes()),
    dispatch(fetchVendorSubTypes()),
    dispatch(fetchByos()),
    dispatch(fetchDcs()),
    dispatch(fetchCountries()),
    ...(
      userProfile.pvendorNumber
        ? [dispatch(fetchPvendor(userProfile.pvendorNumber))]
        : []
    ),
  ]);
};

// helpers
const handleError = (thunk) => async (dispatch) => {
  let dispatchResult;
  try {
    dispatchResult = await dispatch(thunk);
  } catch (err) {
    dispatch(displayError({
      code: err.code,
      message: err.message,
    }));
  } finally {
    return dispatchResult;
  }
};

const withSpinner = (thunk) => async (dispatch) => {
  dispatch(loadingSpinnerOn());
  try {
    return await dispatch(thunk);
  } finally {
    dispatch(loadingSpinnerOff());
  }
};

const withErrorCode = (code, thunk) => async (dispatch) => {
  try {
    return await dispatch(thunk);
  } catch (err) {
    err.code = code;
    throw err;
  }
};

const withLogout = (thunk) => async (dispatch) => {
  try {
    return await dispatch(thunk);
  } catch (err) {
    if ((err instanceof ApiError) && (err.status === 401)) {
      return dispatch(logout());
    }
    throw err;
  }
};

export const handleErrorWithSpinner = _.flow(withLogout, withSpinner, handleError);

export const processPvendorSearch = (pvendorNumber) => (
  handleErrorWithSpinner(withErrorCode('PVENDOR_SEARCH', async (dispatch) => {
    dispatch(clearUserSelectedVariables());
    dispatch(clearError('PVENDOR_SEARCH'));
    pvendorValidation(pvendorNumber);
    await dispatch(fetchPvendor(pvendorNumber));
  }))
);

export const addContact = (pvendorNumber, mvendorNumber, departmentNumber, contact) => (
  async (dispatch) => {
    await dispatch(withErrorCode('SAVE_CONTACT', () => (
      api.addContact(pvendorNumber, mvendorNumber, departmentNumber, contact)
    )));
    dispatch(modalClosed());
    await dispatch(fetchPvendor(pvendorNumber));
  }
);

export const updateContact = (pvendorNumber, mvendorNumber, departmentNumber, contact) => (
  async (dispatch) => {
    await dispatch(withErrorCode('SAVE_CONTACT', () => (
      api.updateContact(pvendorNumber, mvendorNumber, departmentNumber, contact)
    )));
    dispatch(modalClosed());
    await dispatch(fetchPvendor(pvendorNumber));
  }
);

export const deleteContactAssignments = (
  pvendorNumber, mvendorNumber, departmentNumber, contact, deleteAll
) => (
  async (dispatch) => {
    await dispatch(withErrorCode('DELETE_CONTACT', () => (
      api.deleteContactAssignments(
        pvendorNumber, mvendorNumber, departmentNumber, contact, deleteAll
      )
    )));
    dispatch(modalClosed());
    await dispatch(fetchPvendor(pvendorNumber));
  }
);

export const fetchStates = (countryCode) => (
  handleErrorWithSpinner(withErrorCode('FETCH_STATES', async (dispatch, getState) => {
    if (countryCode && !_.has(getCountryStates(getState()), countryCode)) {
      const countryStates = await api.getCountryStates(countryCode);
      dispatch(setCountryStates(countryStates, countryCode));
    }
  }))
);

export const supplierLogout = () => async (dispatch) => {
  await api.deleteSession();
  const environment = await dispatch(fetchEnvironment());

  const supplierUrl = environment.loginUrl;
  const logoutUrl = supplierUrl.replace('login', 'logout');

  window.location.replace(logoutUrl);
};
