import { SET_USER_PROFILE } from '../actions';
import initialState from '../initialState';

function userProfile(state = initialState.userProfile, action) {
  switch (action.type) {
    case SET_USER_PROFILE: {
      return { ...action.userProfile };
    }
    default: {
      return state;
    }
  }
}

export default userProfile;
