import React, { useState } from 'react';
import _ from 'lodash';
import { Table, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import './ContactAssignmentLocationTable.scss';

const ContactAssignmentLocationTable = ({
  title,
  dataSource,
  onChange,
  onRowClick,
  showCheckboxes,
  checkedKeys,
  boldRows,
  'data-testid': testId,
}) => {
  const [selectedRow, setSelectedRow] = useState();

  const canSelectAll = () => _.isEmpty(checkedKeys);

  const selectAll = () => onChange?.(canSelectAll() ? _.map(dataSource, 'key') : []);

  const columns = [
    {
      title:
  <>
    <div className="bold">{title}</div>
    <Button
      type="text"
      className={{
        'select-all': true,
        hidden: !showCheckboxes,
      }}
      onClick={selectAll}
      data-testid="select-all-button"
    >
      {canSelectAll() ? 'Select All' : 'Deselect All'}
    </Button>
  </>,
      dataIndex: 'label',
      render: (text) => (
        onRowClick ? (
          <div className="location-row">
            {text}
            <RightOutlined className="right-row-arrow" />
          </div>
        ) : text
      ),
    },
  ];

  const rowSelection = showCheckboxes && {
    type: 'checkbox',
    hideSelectAll: true,
    selectedRowKeys: checkedKeys,
    onChange: (selectedRowKeys) => onChange?.(selectedRowKeys),
  };

  const getRowClassName = (record) => classNames({
    'selected-row': onRowClick && record.key === selectedRow,
    bold: _.includes(boldRows, record.key),
  });

  return (
    <Table
      className="location-table"
      rowSelection={rowSelection}
      scroll={{ y: '300px' }}
      columns={columns}
      dataSource={dataSource}
      bordered={false}
      pagination={false}
      onRow={(row) => ({
        onClick: () => {
          if (onRowClick) {
            setSelectedRow(row.key);
            onRowClick(row);
          }
        },
      })}
      rowClassName={getRowClassName}
      data-testid={testId}
    />
  );
};
export default ContactAssignmentLocationTable;
