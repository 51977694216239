import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Form, Input, Button, Divider, Select, Checkbox } from 'antd';
import _ from 'lodash';
import fp from 'lodash/fp';
import * as constants from '../redux/selectors/constants';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import './ContactForm.scss';
import { submitAddContactProfile, submitEditContactProfile, clearError } from '../redux/actions';
import * as presentation from '../redux/selectors/presentation';
import { useCountryStates } from '../hooks';

const ContactForm = ({ next, modalType }) => {
  const dispatch = useDispatch();
  const contact = useSelector(userSelectedVariables.getContact);
  const countries = useSelector(constants.generateCountryOptions);
  const selectedMvendor = useSelector(userSelectedVariables.getSelectedMvendor);
  const { FETCH_STATES: fetchStatesError } = useSelector(presentation.getErrors);
  const originalLocationAssignments = useSelector(
    userSelectedVariables.getOriginalLocationAssignments
  );
  const { states, setCountryCode } = useCountryStates(contact?.countryCode);
  const [form] = Form.useForm();

  const onFinish = () => {
    next();
  };

  const hasMultipleLocationAssignments = fp.flow(
    fp.pick(['companies', 'byos', 'markets', 'dcs']),
    fp.values,
    fp.sumBy(fp.size),
    (x) => x > 1
  );

  const handleCountrySelect = (value) => {
    dispatch(clearError('FETCH_STATES'));
    setCountryCode(value);
    form.setFieldsValue({ state: null });
  };

  const saveContactProfile = () => {
    const countryCode = form.getFieldValue('country');
    const stateCode = form.getFieldValue('state');
    const primaryPhone = form.getFieldValue('primary-phone-number');

    const contactProfile = {
      addressNumber: contact?.addressNumber,
      ...(
        _.mapValues(
          {
            contactName: form.getFieldValue('contact-name'),
            contactTitleDescription: form.getFieldValue('job-title'),
            addressLineOneText: form.getFieldValue('street-address'),
            addressLineTwoText: form.getFieldValue('street-address-overflow'),
            addressLineThreeText: null,
            cityName: form.getFieldValue('city'),
            longZipCode: form.getFieldValue('postal-code'),
          },
          (fieldValue) => fieldValue?.trim() ?? null
        )
      ),
      countryCode,
      countryName: _.find(countries, { value: countryCode })?.label,
      electronicMailAddressText: form.getFieldValue('email-address'),
      hostAutomatedFaxNumber: form.getFieldValue('po-fax-number'),
      merchandiseDepartmentNumber: selectedMvendor.departmentNumber,
      merchandiseVendorNumber: selectedMvendor.number,
      phoneAreaCityCode: primaryPhone?.substring(0, 3),
      phoneLocalNumber: primaryPhone?.substring(3),
      phoneNumber: primaryPhone,
      phoneExtensionNumber: form.getFieldValue('primary-phone-extn'),
      stateCode,
      stateName: _.find(states, { code: stateCode })?.name,
    };

    dispatch(
      modalType === 'EDIT'
        ? submitEditContactProfile(contactProfile, form.getFieldValue('edit-all'))
        : submitAddContactProfile(contactProfile)
    );
  };

  const initialValues = {
    'contact-name': _.get(contact, 'contactName', null),
    'email-address': _.get(contact, 'electronicMailAddressText', null),
    'job-title': _.get(contact, 'contactTitleDescription', null),
    'street-address': _.get(contact, 'addressLineOneText', null),
    'street-address-overflow': _.get(contact, 'addressLineTwoText', null),
    city: _.get(contact, 'cityName', null),
    country: _.get(contact, 'countryCode', null),
    state: _.get(contact, 'stateCode', null),
    'postal-code': _.get(contact, 'longZipCode', null),
    'primary-phone-number': _.get(contact, 'phoneNumber', null),
    'primary-phone-extn': _.get(contact, 'phoneExtensionNumber', null),
    'po-fax-number': _.get(contact, 'hostAutomatedFaxNumber', null),
    'edit-all': hasMultipleLocationAssignments(contact),
  };

  const hasCountryStates = states.length > 0;

  useEffect(() => {
    if (fetchStatesError) {
      form.setFieldsValue({ country: null });
      form.validateFields(['country']);
      setCountryCode(null);
    }
  }, [fetchStatesError, form, setCountryCode]);

  return (
    <>
      <h2>Add a Store Contact</h2>
      {fetchStatesError && (
        <Alert
          message="Error: States for the selected country were not loaded successfully. Please try again."
          type="error"
          showIcon
          closable
          afterClose={() => dispatch(clearError('FETCH_STATES'))}
        />
      )}
      <h3> Profile </h3>
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        initialValues={initialValues}
      >
        <div className="contact-container">
          <div className="modal-column">
            <Form.Item
              name="contact-name"
              label="Contact Name"
              className="contact-form-item"
              rules={[
                {
                  required: true,
                  message: 'Input a Contact Name!',
                },
                {
                  max: 24,
                  message: 'The input exceeds the maximum length!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email-address"
              label="Email"
              className="contact-form-item"
              rules={[
                {
                  required: true,
                  message: 'Input an E-mail Address!',
                },
                {
                  type: 'email',
                  message: 'The input is not a valid E-mail Address!',
                },
                {
                  max: 150,
                  message: 'The input exceeds the maximum length!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="job-title"
              label="Job Title"
              className="contact-form-item"
              rules={[
                {
                  required: true,
                  message: 'Input a Job Title!',
                },
                {
                  pattern: '^[A-Za-z ]*[A-Za-z][A-Za-z ]*$',
                  message: 'The input is not a valid Job Title!',
                },
                {
                  max: 18,
                  message: 'The input exceeds the maximum length!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              className="contact-form-item__grouping"
            >
              <Form.Item
                name="street-address"
                label="Street Address"
                rules={[
                  {
                    required: true,
                    message: 'Input an Address!',
                  },
                  {
                    max: 24,
                    message: 'The input exceeds the maximum length!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="street-address-overflow"
                rules={[
                  {
                    max: 24,
                    message: 'The input exceeds the maximum length!',
                  },
                ]}
              >
                <Input data-testid="street-address-overflow" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="city"
              label="City"
              className="contact-form-item"
              rules={[
                {
                  required: true,
                  message: 'Input a City!',
                },
                {
                  pattern: '^[A-Za-z ]*[A-Za-z][A-Za-z ]*$',
                  message: 'The input is not a valid City!',
                },
                {
                  max: 18,
                  message: 'The input exceeds the maximum length!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <div>
              <Form.Item
                name="country"
                label="Country"
                className="contact-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Select a Country!',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  options={countries}
                  allowClear
                  onSelect={handleCountrySelect}
                />
              </Form.Item>
            </div>

            <div className="container">
              <div className="contact-form-item contact-form-item--state">
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: hasCountryStates,
                      message: 'Select a State!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    data-testid="select"
                    optionFilterProp="label"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    options={_.map(states, (state) => ({
                      value: state.code,
                      label: state.name,
                    }))}
                    allowClear
                    disabled={!hasCountryStates}
                  />
                </Form.Item>
              </div>
              <div className="contact-form-item contact-form--item--zip">
                <Form.Item
                  name="postal-code"
                  label="Postal Code"
                  rules={[
                    {
                      required: true,
                      message: 'Input a Postal Code!',
                    },
                    {
                      pattern: '^[a-zA-Z0-9- ]+$',
                      message: 'Input valid Postal Code!',
                    },
                    {
                      max: 10,
                      message: 'The input exceeds the maximum length!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="modal-column">
            <Form.Item label="Primary Phone Number" className="contact-form-item__grouping" required>
              <div className="container">
                <Form.Item
                  className="contact-form-item contact-form-item--number-input"
                  name="primary-phone-number"
                  rules={[
                    {
                      required: true,
                      message: 'Input a Phone Number!',
                    },
                    {
                      max: 10,
                      min: 10,
                      pattern: '^[0-9]*$',
                      message: 'Input a valid Phone Number!',
                    },
                  ]}
                >
                  <Input data-testid="phone-number-input-1" />
                </Form.Item>
                <Form.Item
                  className="contact-form-item contact-form-item--extn"
                  name="primary-phone-extn"
                  rules={[
                    {
                      max: 5,
                      pattern: '^[0-9]*$',
                      message: 'Invalid Extn!',
                    },
                  ]}
                >
                  <Input placeholder="Extn." data-testid="phone-extn-input-1" />
                </Form.Item>
              </div>
            </Form.Item>

            <Form.Item label="PO Auto Fax Number (Optional)" className="contact-form-item__grouping">
              <div className="container">
                <Form.Item
                  className="contact-form-item contact-form-item--number-input"
                  name="po-fax-number"
                  rules={[
                    {
                      max: 10,
                      min: 10,
                      pattern: '^[0-9]*$',
                      message: 'Input a valid PO Fax Number!',
                    },
                  ]}
                >
                  <Input data-testid="po-fax-input" />
                </Form.Item>
              </div>
            </Form.Item>

            {(modalType === 'EDIT' && hasMultipleLocationAssignments(originalLocationAssignments))
            && (
              <Form.Item name="edit-all" valuePropName="checked">
                <Checkbox
                  data-testid="edit-all-checkbox"
                >
                  Select to update all location assignments for this Contact
                </Checkbox>
              </Form.Item>
            )}
          </div>
        </div>
        <Divider />
        <Form.Item className="text-centered contact-form-item__grouping">
          <Button type="primary" htmlType="submit" onClick={saveContactProfile}>
            Next Step
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ContactForm;
