import './Header.scss';
import { Layout, Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThdIcon from '../components/ThdIcon';
import { handleErrorWithSpinner, supplierLogout } from '../redux/actions';
import * as userProfile from '../redux/selectors/userProfile';

const AntHeader = Layout.Header;

const Header = () => {
  const dispatch = useDispatch();
  const firstName = useSelector(userProfile.getFirstName);

  return (
    <AntHeader className="sc-header">
      <div className="top-header">
        <p>Welcome</p>
        <p id="user-name">{firstName}</p>
      </div>

      <div className="bottom-header">
        <div id="page-title">
          <ThdIcon size="3.5em" />
          <p>Store Contacts</p>
        </div>
        <div id="exit">
          <Button className="logoutButton" onClick={() => dispatch(handleErrorWithSpinner(supplierLogout()))} ghost>
            <p>Logout</p>
          </Button>
        </div>
      </div>
    </AntHeader>
  );
};

export default Header;
