import React from 'react';
import { Alert, Table } from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import * as presentation from '../redux/selectors/presentation';
import { clearError } from '../redux/actions';
import './Summary.scss';

const Summary = () => {
  const dispatch = useDispatch();
  const contact = useSelector(userSelectedVariables.getContact);
  const byoDataSource = useSelector(userSelectedVariables.getSummaryByoTableEntries);
  const marketDataSource = useSelector(userSelectedVariables.getSummaryMarketTableEntries);
  const dcDataSource = useSelector(userSelectedVariables.getSummaryDcTableEntries);
  const { SAVE_CONTACT: saveContactError } = useSelector(presentation.getErrors);

  const formatPhone = (phone) => {
    let formattedPhoneNumber;
    if (phone != null) {
      formattedPhoneNumber = phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return formattedPhoneNumber;
  };
  const showState = () => {
    if (contact.stateCode) {
      return contact.countryCode === 'US' ? contact.stateCode : contact.stateName;
    }
    return '';
  };

  const contactAssignedCorporate = () => (!_.isEmpty(contact.companies)
    ? 'Assigned' : 'Not Assigned');

  const contactAssignedByos = () => (!_.isEmpty(contact.byos)
    ? _.map(byoDataSource, (byo) => (
      <div key={byo.key}>{byo.label}</div>)) : 'Not Assigned');

  const contactAssignedMarkets = () => (!_.isEmpty(contact.markets)
    ? _.map(marketDataSource, (market) => (
      <div key={market.key}>{market.label}</div>)) : 'Not Assigned');

  const contactAssignedDcs = () => (!_.isEmpty(contact.dcs)
    ? _.map(dcDataSource, (dc) => (
      <div key={dc.key}>{dc.label}</div>)) : 'Not Assigned');

  const dataSource = [
    {
      key: 'contact-assignment-summary',
      corporate: contactAssignedCorporate(),
      byo: contactAssignedByos(),
      market: contactAssignedMarkets(),
      dc: contactAssignedDcs(),
    },
  ];

  const column = [
    {
      className: 'corp-market-column',
      title: 'Corporate',
      dataIndex: 'corporate',
      key: 'corporate',
      width: '25%',
    },
    {
      className: 'byo-dc-column',
      title: 'BYO',
      dataIndex: 'byo',
      key: 'byo',
      width: '25%',
    },
    {
      className: 'corp-market-column',
      title: 'Market',
      dataIndex: 'market',
      key: 'market',
      width: '25%',
    },
    {
      className: 'byo-dc-column',
      title: 'DC',
      dataIndex: 'dc',
      key: 'dc',
      width: '25%',
    },
  ];
  return (
    <>
      <h3 className="bold">Store Contact Summary</h3>
      {saveContactError && (
        <Alert message="Error saving Contact. Please try again." type="error" showIcon closable afterClose={() => dispatch(clearError('SAVE_CONTACT'))} />
      )}
      <div className="container">
        <div className="modal-column modal-column--summary">
          <div className="bold">{contact.contactName}</div>
          <div>{contact.contactTitleDescription}</div>
          <div>{contact.electronicMailAddressText}</div>
          <br />
          <div>
            {`${formatPhone(contact.phoneNumber)} ${contact.phoneExtensionNumber ? `Ext. ${contact.phoneExtensionNumber}` : ''} (Primary)`}
          </div>
        </div>
        <div className="modal-column">
          {contact.hostAutomatedFaxNumber
            ? (
              <>
                <br />
                <div>PO Auto Fax</div>
                <div>{formatPhone(contact.hostAutomatedFaxNumber)}</div>
                <br />
              </>
            ) : ''}
          <div>{contact.addressLineOneText}</div>
          {contact.addressLineTwoText ? (
            <div>{contact.addressLineTwoText}</div>
          ) : null}
          <div>{`${contact.cityName}, ${showState()} ${contact.longZipCode}`}</div>
          <div>{contact.countryName}</div>
        </div>
      </div>
      <h4 className="assignment-locations"> Assignment Locations </h4>
      <div className="contact-assignment-summary-table">
        <Table
          data-testid="contact-assignment-summary"
          dataSource={dataSource}
          columns={column}
          pagination={false}
          scroll={{ y: '300px' }}
        />
      </div>
    </>
  );
};
export default Summary;
