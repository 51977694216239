import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import * as constants from '../redux/selectors/constants';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import * as presentation from '../redux/selectors/presentation';
import { setContactDcAssignments } from '../redux/actions';
import ContactAssignmentLocationTable from './ContactAssignmentLocationTable';
import './ContactAssignmentDcTable.scss';

const ContactAssignmentDcTable = () => {
  const dispatch = useDispatch();
  const dcDataSource = useSelector(constants.getContactAssignmentDcTableEntries);
  const checkedDcs = useSelector(userSelectedVariables.getContactDcAssignments);
  const { ASSIGNMENT_ERROR: assignmentError } = useSelector(presentation.getErrors);

  const onCheckedDcsChange = (selectedRowKeys) => {
    dispatch(setContactDcAssignments(selectedRowKeys));
  };

  return (
    <>
      <div className={classNames('container', 'dc-table', { 'red-border': assignmentError })}>
        <ContactAssignmentLocationTable
          title="DC"
          dataSource={dcDataSource}
          showCheckboxes
          checkedKeys={checkedDcs}
          onChange={onCheckedDcsChange}
          data-testid="dc-table"
        />
      </div>

      <p className="assignment-error-message">
        { assignmentError }
      </p>
    </>
  );
};
export default ContactAssignmentDcTable;
