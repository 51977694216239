import _ from 'lodash';
import fp from 'lodash/fp';
import { createSelector } from 'reselect';

export const getFirstName = (state) => _.get(state, 'userProfile.firstName') || 'Unknown User';

export const isUserAuthenticated = (state) => _.get(state, 'userProfile.isAuthenticated');

export const getPvendorNumber = (state) => _.get(state, 'userProfile.pvendorNumber');

export const isUserInternal = fp.flow(fp.get('userProfile.role'), (x) => _.includes(['INTERNAL_EDIT', 'INTERNAL_READONLY'], x));

export const canUserEdit = fp.flow(fp.get('userProfile.role'), (x) => _.includes(['INTERNAL_EDIT', 'EXTERNAL_EDIT'], x));

export const isAuthenticationCheckComplete = createSelector(
  isUserAuthenticated,
  fp.negate(fp.isUndefined)
);
