import { combineReducers } from 'redux';
import constants from './constants';
import environment from './environment';
import presentation from './presentation';
import userSelectedVariables from './userSelectedVariables';
import userProfile from './userProfile';

const appReducer = combineReducers({
  constants,
  environment,
  presentation,
  userSelectedVariables,
  userProfile,
});

const rootReducer = (state, action) => {
  if (action.type === 'REFRESH') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
