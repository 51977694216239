import _ from 'lodash';
import fp from 'lodash/fp';
import { createSelector } from 'reselect';

export const getEnvironment = (state) => _.get(state, 'environment');

export const getLoginUrl = (state) => _.get(state, 'environment.loginUrl');

export const isLoginUrlLoaded = createSelector(
  getLoginUrl,
  fp.negate(fp.isUndefined)
);
