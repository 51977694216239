import _ from 'lodash';
import { SET_VENDOR_SUB_TYPES, SET_VENDOR_TYPES, SET_BYOS, SET_DCS, SET_COUNTRIES } from '../actions/index';
import initialState from '../initialState';

function constants(state = initialState.constants, action) {
  switch (action.type) {
    case SET_VENDOR_SUB_TYPES: {
      return {
        ...state,
        vendorSubTypes: _.keyBy(
          action.vendorSubTypes,
          'merchandiseVendorSubordinateTypeCode'
        ),
      };
    }
    case SET_VENDOR_TYPES: {
      return {
        ...state,
        vendorTypes: _.keyBy(action.vendorTypes, 'merchandiseVendorTypeCode'),
      };
    }
    case SET_BYOS: {
      const { byos } = action;
      return { ...state, byos };
    }
    case SET_DCS: {
      const { dcs } = action;
      return { ...state, dcs };
    }
    case SET_COUNTRIES: {
      const { countries } = action;
      return { ...state, countries };
    }
    default: {
      return state;
    }
  }
}

export default constants;
