import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import ResultItem from '../components/ResultItem';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import './PvendorInfo.scss';

const PvendorInfo = () => {
  const pvendorNumber = useSelector(userSelectedVariables.getPvendorNumber);
  const pvendorName = useSelector(userSelectedVariables.getPvendorName);
  const partnerTypes = useSelector(userSelectedVariables.getPartnerTypes);

  return (
    <div className="section">
      <h2> PVendor </h2>
      <div className="pvendor-grid">
        <ResultItem header="Pvendor Number">{pvendorNumber}</ResultItem>
        <ResultItem header="Pvendor Name">
          <div>{pvendorName}</div>
        </ResultItem>
        <ResultItem header="Partner Type">
          {
            partnerTypes.length === 0
              ? 'N/A'
              : _.map(partnerTypes, (partnerType, index) => (
                <div key={index}>{partnerType}</div>
              ))
          }
        </ResultItem>
      </div>
    </div>
  );
};

export default PvendorInfo;
