import React, { useState } from 'react';
import { Button, Tabs } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import CorporateContactsTable from './CorporateContactsTable';
import DcContactsTable from './DcContactsTable';
import MarketContactsTable from './MarketContactsTable';
import ContactModal from './ContactModal';
import DeleteContactModal from './DeleteContactModal';
import * as userProfile from '../redux/selectors/userProfile';
import { isFeatureEnabled } from '../util/features';
import { editContactInit, addContactInit, deleteContactInit } from '../redux/actions';
import * as presentation from '../redux/selectors/presentation';
import './ContactsInfo.scss';

const ContactsInfo = () => {
  const dispatch = useDispatch();
  const addEditModalVisibility = useSelector(presentation.getAddEditModalVisibility);
  const deleteModalVisibility = useSelector(presentation.getDeleteModalVisibility);
  const [modalType, setModalType] = useState(false);
  const canUserEdit = useSelector(userProfile.canUserEdit);
  const { TabPane } = Tabs;

  const showEditModal = (contact, locationAssignment) => {
    dispatch(editContactInit(contact, locationAssignment));
    setModalType('EDIT');
  };

  const showAddModal = () => {
    dispatch(addContactInit());
    setModalType('ADD');
  };

  const showDeleteModal = (contact, locationAssignment) => {
    dispatch(deleteContactInit(contact, locationAssignment));
  };

  const editEnabled = canUserEdit && isFeatureEnabled('edit-contacts');

  return (
    <div className="section section--contacts">
      <Tabs defaultActiveKey="corporate" className="contacts-tabs">
        <TabPane tab="Corporate" key="corporate">
          <div className="tab-title-container">
            <h2>Corporate</h2>
            {editEnabled && <Button onClick={showAddModal} type="primary">Add Contact</Button>}
          </div>
          <CorporateContactsTable
            showEditColumns={editEnabled}
            onEditIconClick={showEditModal}
            onDeleteIconClick={showDeleteModal}
          />
        </TabPane>
        <TabPane tab="BYO/Market" key="byo-market">
          <div className="tab-title-container">
            <h2>BYO/Market</h2>
            {editEnabled && <Button onClick={showAddModal} type="primary">Add Contact</Button>}
          </div>
          <MarketContactsTable
            showEditColumns={editEnabled}
            onEditIconClick={showEditModal}
            onDeleteIconClick={showDeleteModal}
          />
        </TabPane>
        <TabPane tab="DC" key="dc">
          <div className="tab-title-container">
            <h2>DC</h2>
            {editEnabled && <Button onClick={showAddModal} type="primary">Add Contact</Button>}
          </div>
          <DcContactsTable
            showEditColumns={editEnabled}
            onEditIconClick={showEditModal}
            onDeleteIconClick={showDeleteModal}
          />
        </TabPane>
      </Tabs>
      <ContactModal
        modalVisibility={addEditModalVisibility}
        modalType={modalType}
      />
      <DeleteContactModal
        modalVisibility={deleteModalVisibility}
      />
    </div>
  );
};

export default ContactsInfo;
