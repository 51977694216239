const initialState = {
  constants: {},
  environment: {},
  presentation: {
    errors: {},
    loadingSpinner: false,
  },
  userProfile: {},
  userSelectedVariables: {},
};

export default initialState;
