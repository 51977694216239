import { isEmpty } from 'lodash';

const pvendorValidation = (pvendorNumber) => {
  if (isEmpty(pvendorNumber)) {
    throw new Error('Invalid entry - Field cannot be blank');
  } else if (!/^[0-9]+$/.test(pvendorNumber)) {
    throw new Error('Invalid entry - Entry must be numerals only (0-9)');
  } else if (pvendorNumber.length > 10) {
    throw new Error('Invalid entry - Entry cannot be greater than 10 characters');
  }
};

export default pvendorValidation;
