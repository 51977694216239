import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import { processPvendorSearch } from '../redux/actions';
import * as presentation from '../redux/selectors/presentation';
import './PvendorSearch.scss';

const { Search } = Input;

const PvendorSearch = () => {
  const dispatch = useDispatch();
  const { PVENDOR_SEARCH: searchError } = useSelector(presentation.getErrors);

  return (
    <div className="section">
      <div>
        <Search
          data-testid="search-bar"
          className={
            searchError ? 'search-bar input-error' : 'search-bar'
          }
          placeholder="Enter PVendor number"
          style={{ width: 350 }}
          onSearch={(value) => dispatch(processPvendorSearch(value))}
        />
        {searchError && <p id="error-message">{searchError}</p>}
      </div>
    </div>
  );
};

export default PvendorSearch;
