import _ from 'lodash';
import {
  APP_INIT_START,
  CLEAR_ERROR,
  DISPLAY_ERROR,
  LOADING_SPINNER_ON,
  LOADING_SPINNER_OFF,
  MODAL_CLOSED,
  ADD_CONTACT_INIT,
  EDIT_CONTACT_INIT,
  DELETE_CONTACT_INIT,
} from '../actions';
import initialState from '../initialState';

function presentation(state = initialState.presentation, action) {
  switch (action.type) {
    case APP_INIT_START: {
      return { ...state, isAppInitStart: true };
    }
    case MODAL_CLOSED: {
      return { ...state, addEditModalVisible: false, deleteModalVisible: false, errors: {} };
    }
    case CLEAR_ERROR: {
      const { code } = action;
      return { ...state, errors: _.omit(state.errors, code) };
    }
    case DISPLAY_ERROR: {
      const { error } = action;
      return { ...state, errors: { ...state.errors, [error.code ?? 'GENERIC']: error.message } };
    }
    case LOADING_SPINNER_ON: {
      return { ...state, loadingSpinner: true };
    }
    case LOADING_SPINNER_OFF: {
      return { ...state, loadingSpinner: false };
    }
    case ADD_CONTACT_INIT: case EDIT_CONTACT_INIT: {
      return { ...state, addEditModalVisible: true };
    }
    case DELETE_CONTACT_INIT: {
      return { ...state, deleteModalVisible: true };
    }
    default: {
      return state;
    }
  }
}

export default presentation;
