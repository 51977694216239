import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import * as selectors from '../redux/selectors';
import { getSelectedMvendorContactsAssignedToDcs } from '../redux/selectors/userSelectedVariables';
import EditIcon from '../components/EditIcon';
import DeleteIcon from '../components/DeleteIcon';

const DcContactsTable = ({ showEditColumns, onEditIconClick, onDeleteIconClick }) => {
  const dataSource = useSelector(selectors.getDcTableData);
  const dcContactList = useSelector(getSelectedMvendorContactsAssignedToDcs);

  const onIconClick = (callback, record) => {
    callback(
      _.find(dcContactList, { addressNumber: record.id }),
      { dcs: [record.dcNumber] }
    );
  };

  const editUserColumns = [
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      width: '5%',
      className: 'icon-column',
      render: (text, record) => (
        <EditIcon onClick={() => onIconClick(onEditIconClick, record)} />
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      width: '5%',
      className: 'icon-column',
      render: (text, record) => (
        <DeleteIcon onClick={() => onIconClick(onDeleteIconClick, record)} />
      ) },
  ];

  const columns = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: showEditColumns ? '28%' : '30%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: showEditColumns ? '13%' : '15%',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: showEditColumns ? '13%' : '15%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: showEditColumns ? '18%' : '20%',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: showEditColumns ? '18%' : '20%',
    },
    ...(showEditColumns ? editUserColumns : []),
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      bordered
    />
  );
};

export default DcContactsTable;
