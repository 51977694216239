import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import * as presentation from '../redux/selectors/presentation';
import { setContactCorporateAssignment, setContactCorporateUnassignment } from '../redux/actions';

const ContactAssignmentCorporateTable = () => {
  const isCorporateAssigned = useSelector(userSelectedVariables.isCorporateAssigned);
  const dispatch = useDispatch();
  const { ASSIGNMENT_ERROR: assignmentError } = useSelector(presentation.getErrors);

  const onCheckedCorporateChange = (e) => {
    dispatch(
      e.target.checked ? setContactCorporateAssignment() : setContactCorporateUnassignment()
    );
  };
  return (
    <>
      <div className={classNames({ 'red-border': assignmentError })}>
        <Checkbox
          data-testid="corp-checkbox"
          defaultChecked={isCorporateAssigned}
          onChange={onCheckedCorporateChange}
        >
          CORPORATE
        </Checkbox>
      </div>

      <p className="assignment-error-message">
        { assignmentError }
      </p>
    </>
  );
};

export default ContactAssignmentCorporateTable;
