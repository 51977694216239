import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Checkbox, Button } from 'antd';
import './DeleteContactModalContent.scss';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import * as presentation from '../redux/selectors/presentation';
import { handleErrorWithSpinner, deleteContactAssignments, changeDeleteAssignments, modalClosed, clearError } from '../redux/actions';

const DeleteContactModalContent = () => {
  const dispatch = useDispatch();
  const contact = useSelector(userSelectedVariables.getContact);
  const pvendor = useSelector(userSelectedVariables.getPvendorNumber);
  const mvendor = useSelector(userSelectedVariables.getSelectedMvendor);
  const { DELETE_CONTACT: deleteContactError } = useSelector(presentation.getErrors);
  const originalLocations = useSelector(userSelectedVariables.getOriginalLocationAssignments);
  const hasSingleAssignment = _.chain(originalLocations)
    .omitBy((assignments) => assignments.length === 0)
    .values()
    .thru((assignments) => assignments.length === 1 && assignments[0].length === 1)
    .value();
  const [deleteAll, setDeleteAll] = useState(hasSingleAssignment);
  const deleteAssignments = async () => {
    await dispatch(handleErrorWithSpinner(
      deleteContactAssignments(
        pvendor, mvendor.number, mvendor.departmentNumber, contact, deleteAll
      )
    ));
  };

  return (
    <>
      <h3>Delete Contact from this Location?</h3>
      {deleteContactError && (
        <Alert message="Error deleting Contact. Please try again." type="error" showIcon closable afterClose={() => dispatch(clearError('DELETE_CONTACT'))} />
      )}
      <p>
        Deleting contact will remove them from this location assignment.
        This action cannot be undone.
      </p>

      { !hasSingleAssignment && (
      <Checkbox
        data-testid="delete-contact-checkbox"
        onChange={(e) => {
          dispatch(changeDeleteAssignments(contact, e.target.checked));
          setDeleteAll(e.target.checked);
        }}
      >
        Select to delete contact from all assigned locations
      </Checkbox>
      )}
      <div className="delete-modal-content-footer">
        <Button
          key="cancel-delete"
          onClick={() => {
            dispatch(modalClosed());
          }}
        >
          Cancel
        </Button>
        <Button
          key="confirm-delete"
          onClick={() => {
            deleteAssignments();
          }}
          type="primary"
        >
          Delete
        </Button>
      </div>
    </>
  );
};

export default DeleteContactModalContent;
