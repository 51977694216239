import React from 'react';
import Icon from '@ant-design/icons';

// WIDTH AND HEIGHT MUST BE IN EM SO IT WILL CHANGE SIZE IN RESPECT TO <i>'s font-size
const ThdSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 192 192">
    <polygon
      id="Fill-1"
      fill="#FFFFFE"
      points="0 192.94 192.756 192.94 192.756 0.244 0 0.244"
    />
    <polygon
      id="Fill-2"
      fill="#F96302"
      points="8.504 184.496 184.252 184.496 184.252 8.748 8.504 8.748"
    />
    <path
      d="M47.492,147.87 L42.931,152.428 L39.931,149.422 L44.483,144.864 L32.698,133.085 C30.786,131.168 30.524,130.806 28.139,132.044 C27.264,132.513 26.322,132.507 25.603,131.78 C24.098,130.277 25.704,128.474 25.913,128.26 L40.565,113.61 C40.772,113.403 42.579,111.801 44.084,113.296 C44.812,114.027 44.818,114.959 44.35,115.837 C43.105,118.221 43.47,118.479 45.388,120.395 L72.575,147.588 C74.492,149.512 74.752,149.877 77.139,148.626 C78.022,148.165 78.949,148.169 79.678,148.892 C81.179,150.398 79.58,152.211 79.375,152.42 L64.72,167.067 C64.509,167.273 62.696,168.876 61.194,167.379 C60.472,166.647 60.47,165.716 60.93,164.835 C62.179,162.454 61.813,162.193 59.894,160.274 L47.492,147.87 Z M47.294,184.496 L41.317,184.496 C41.332,184.465 41.347,184.435 41.363,184.404 C42.601,182.029 42.242,181.764 40.324,179.846 L13.133,152.659 C11.209,150.734 10.954,150.371 8.571,151.612 C8.549,151.624 8.526,151.636 8.504,151.647 L8.504,145.667 L20.995,133.177 C21.201,132.976 23.011,131.365 24.512,132.864 C25.237,133.596 25.246,134.526 24.779,135.405 C23.532,137.795 23.896,138.05 25.82,139.974 L53.008,167.157 C54.928,169.083 55.185,169.446 57.571,168.202 C58.45,167.735 59.384,167.735 60.11,168.463 C61.611,169.97 60.007,171.779 59.802,171.985 L47.294,184.496 L47.294,184.496 Z"
      id="Fill-3"
      fill="#FFFFFE"
    />
    <path
      d="M121.944,32.231 L135.605,18.565 L174.421,57.364 L160.754,71.038 C160.546,71.245 158.728,72.849 157.227,71.347 C156.501,70.62 156.501,69.682 156.967,68.807 C158.207,66.424 157.85,66.163 155.926,64.24 L128.731,37.051 C126.812,35.137 126.556,34.771 124.171,36.015 C123.289,36.48 122.354,36.48 121.632,35.753 C120.128,34.254 121.738,32.434 121.944,32.231 Z M145.423,8.748 L153.551,8.748 L160.016,15.216 C160.277,15.474 161.215,16.518 159.657,18.063 C158.775,18.947 157.996,18.688 156.961,18.173 C151.415,15.305 146.23,15.413 140.947,19.24 L137.935,16.235 L145.423,8.748 Z M184.252,38.73 L184.252,47.538 L176.748,55.037 L173.744,52.032 C177.422,47.316 177.771,41.253 175.019,35.709 C174.604,34.566 174.083,33.422 175.015,32.496 C176.675,30.837 178.071,32.551 178.278,32.761 L184.252,38.73 Z M155.272,33.56 C156.876,30.816 155.832,27.81 154.325,25.368 C153.713,24.235 153.189,23.086 154.274,22 C155.877,20.403 157.494,22.524 157.909,22.939 L169.111,34.142 C169.526,34.556 171.651,36.17 170.052,37.773 C168.961,38.864 167.822,38.334 166.675,37.716 C164.237,36.214 161.232,35.179 158.489,36.773 L155.272,33.56 L155.272,33.56 Z"
      id="Fill-4"
      fill="#FFFFFE"
    />
    <path
      d="M107.105,178.088 L100.695,184.496 L97.285,184.496 C97.375,184.293 97.487,184.084 97.591,183.863 C97.975,183.138 97.128,182.402 96.692,181.966 L81.405,166.679 C80.965,166.243 80.239,165.397 79.511,165.777 C78.724,166.156 78.083,166.623 77.35,165.897 C76.594,165.138 77.235,164.317 77.819,163.739 L85.286,156.272 L107.105,178.088"
      id="Fill-5"
      fill="#FFFFFE"
    />
    <path
      d="M155.991,85.562 L163.14,78.415 L181.691,96.967 C182.769,98.047 182.919,98.251 184.252,97.554 L184.252,100.943 L177.258,107.933 C177.142,108.05 176.121,108.958 175.274,108.107 C174.867,107.701 174.866,107.178 175.128,106.682 C175.831,105.341 175.623,105.195 174.548,104.116 L155.991,85.562 Z M175.451,79.696 C175.567,79.815 176.209,80.63 175.331,81.504 C174.516,82.319 173.612,81.71 172.886,81.151 C171.075,79.873 168.741,78.357 166.147,78.797 L164.451,77.104 L168.652,72.9 L175.451,79.696 Z M150.482,91.074 L154.681,86.875 L156.375,88.566 C155.934,91.164 157.452,93.495 158.736,95.303 C159.29,96.033 159.899,96.938 159.084,97.752 C158.211,98.628 157.395,97.99 157.276,97.869 L150.482,91.074 L150.482,91.074 Z"
      id="Fill-6"
      fill="#FFFFFE"
    />
    <path
      d="M170.315,175.903 C170.315,173.067 172.613,170.977 175.331,170.977 C178.023,170.977 180.325,173.067 180.325,175.903 C180.325,178.767 178.023,180.856 175.331,180.856 C172.615,180.856 170.315,178.767 170.315,175.903 Z M175.331,180.033 C177.569,180.033 179.332,178.283 179.332,175.903 C179.332,173.564 177.569,171.802 175.331,171.802 C173.07,171.802 171.307,173.564 171.308,175.903 C171.308,178.283 173.07,180.033 175.331,180.033 Z M174.288,178.767 L173.426,178.767 L173.425,173.067 L175.594,173.067 C176.941,173.067 177.606,173.564 177.606,174.688 C177.606,175.706 176.965,176.153 176.131,176.255 L177.751,178.767 L176.783,178.767 L175.28,176.297 L174.286,176.297 L174.288,178.767 Z M175.32,175.564 C176.051,175.564 176.705,175.511 176.705,174.636 C176.705,173.931 176.066,173.8 175.462,173.8 L174.286,173.8 L174.288,175.564 L175.32,175.564 L175.32,175.564 Z"
      id="Fill-7"
      fill="#FFFFFE"
    />
    <path
      d="M61.433,97.201 C59.879,100.514 61.231,102.276 62.53,103.573 L89.404,130.448 C90.702,131.747 92.468,133.095 95.78,131.543 L98.789,134.555 C86.827,144.544 72.833,140.8 62.505,130.476 C52.183,120.148 48.435,106.15 58.425,94.191 L61.433,97.201 Z M98.114,129.218 C99.664,125.907 98.315,124.135 97.011,122.841 L70.141,95.961 C68.842,94.668 67.075,93.319 63.768,94.873 L60.756,91.866 C72.714,81.874 86.715,85.617 97.04,95.941 C107.362,106.267 111.11,120.262 101.119,132.226 L98.114,129.218 L98.114,129.218 Z"
      id="Fill-8"
      fill="#FFFFFE"
    />
    <path
      d="M155.323,73.253 C154.591,72.533 153.663,72.524 152.779,72.994 C150.395,74.235 150.142,73.873 148.219,71.951 L121.027,44.765 C119.104,42.846 118.738,42.589 119.99,40.199 C120.447,39.322 120.447,38.385 119.724,37.659 C118.22,36.156 116.41,37.763 116.206,37.968 L102.533,51.635 L113.698,72.327 L113.59,72.426 L92.131,62.044 L78.986,75.188 C78.367,75.807 76.807,77.36 78.412,78.97 C79.656,80.219 81.162,78.924 81.832,78.351 C82.767,77.526 83.601,77.94 84.839,78.254 C85.929,78.514 86.966,79.035 88.004,79.559 L130.932,100.848 L132.432,99.349 L111.433,60.539 L135.528,84.634 C137.45,86.556 137.813,86.818 136.569,89.196 C136.098,90.083 136.105,91.012 136.838,91.738 C138.335,93.239 140.147,91.64 140.357,91.429 L155.015,76.776 C155.226,76.568 156.822,74.754 155.323,73.253"
      id="Fill-9"
      fill="#FFFFFE"
    />
    <path
      d="M114.765,100.622 L105.843,91.7 L94.386,86.037 L94.281,86.143 L111.762,103.628 C117.419,110.941 112.087,112.233 114.32,114.466 C115.927,116.068 117.943,113.842 118.511,113.269 L124.414,107.374 C124.982,106.803 127.204,104.791 125.594,103.183 C123.365,100.95 122.079,106.282 114.765,100.622"
      id="Fill-10"
      fill="#FFFFFE"
    />
    <path
      d="M86.596,154.955 C91.496,149.936 99.079,149.237 106.958,156.418 C114.132,162.948 113.259,171.931 108.417,176.775 L106.726,175.083 C108.273,173.067 106.696,171.316 105.584,170.212 L93.161,157.782 C92.051,156.676 90.301,155.103 88.291,156.647 L86.596,154.955"
      id="Fill-11"
      fill="#FFFFFE"
    />
    <path
      d="M97.474,144.078 L105.173,136.379 L126.993,158.199 L119.294,165.899 C119.177,166.012 118.155,166.918 117.308,166.074 C116.902,165.665 116.899,165.14 117.161,164.644 C117.865,163.305 117.66,163.158 116.582,162.076 L101.297,146.791 C100.217,145.715 100.068,145.509 98.729,146.205 C98.232,146.474 97.706,146.472 97.297,146.064 C96.452,145.22 97.359,144.194 97.474,144.078 Z M106.487,135.065 L112.99,128.562 L118.913,134.48 C119.057,134.628 119.587,135.21 118.71,136.088 C118.212,136.584 117.781,136.438 117.194,136.145 C114.07,134.542 111.156,134.601 108.178,136.755 L106.487,135.065 Z M126.615,155.194 C128.684,152.541 128.89,149.126 127.343,146 C127.113,145.361 126.815,144.72 127.34,144.195 C128.277,143.261 129.064,144.223 129.181,144.342 L135.017,150.178 L128.308,156.887 L126.615,155.194 Z M116.227,144.806 C117.133,143.263 116.55,141.573 115.704,140.201 C115.351,139.559 115.059,138.912 115.674,138.299 C116.579,137.398 117.484,138.594 117.719,138.828 L124.021,145.132 C124.251,145.361 125.447,146.266 124.539,147.171 C123.931,147.786 123.288,147.493 122.644,147.14 C121.275,146.299 119.587,145.712 118.038,146.618 L116.227,144.806 L116.227,144.806 Z"
      id="Fill-12"
      fill="#FFFFFE"
    />
    <path
      d="M124.249,117.303 C127.022,114.53 128.946,113.185 131.108,112.607 C134.553,111.729 137.322,112.809 139.776,115.256 C142.223,117.704 143.303,120.479 142.428,123.923 C141.841,126.084 140.502,128.011 137.728,130.78 L136.036,129.092 C137.436,127.044 136.331,125.762 134.902,124.329 L130.497,119.925 C129.184,118.616 127.722,117.68 125.941,118.995 L124.249,117.303 Z M119.054,129.035 C117.976,127.955 117.83,127.752 116.486,128.448 C115.991,128.714 115.467,128.709 115.059,128.299 C114.215,127.456 115.119,126.433 115.234,126.316 L122.935,118.619 L141.491,137.174 C142.566,138.248 142.712,138.458 144.055,137.754 C144.553,137.493 145.078,137.491 145.487,137.898 C146.335,138.743 145.43,139.765 145.312,139.88 L137.056,148.137 C136.942,148.259 136.147,148.931 135.302,148.088 C134.893,147.677 134.822,147.22 135.085,146.723 C135.786,145.384 135.419,145.396 134.341,144.315 L119.054,129.035 L119.054,129.035 Z"
      id="Fill-13"
      fill="#FFFFFE"
    />
    <path
      d="M142.741,101.327 C141.864,103.193 142.62,104.183 143.349,104.91 L158.463,120.02 C159.193,120.751 160.184,121.509 162.05,120.633 L163.743,122.325 C157.005,127.958 149.128,125.861 143.326,120.052 C137.517,114.247 135.417,106.371 141.045,99.63 L142.741,101.327 Z M163.361,119.322 C164.237,117.456 163.479,116.466 162.748,115.734 L147.639,100.625 C146.907,99.891 145.918,99.136 144.05,100.008 L142.361,98.319 C149.101,92.686 156.976,94.79 162.778,100.595 C168.583,106.401 170.689,114.271 165.057,121.015 L163.361,119.322 L163.361,119.322 Z"
      id="Fill-14"
      fill="#FFFFFE"
    />
    <path
      d="M29.564,75.295 L36.282,68.578 L53.719,86.015 C54.734,87.03 54.871,87.222 56.132,86.564 C56.598,86.317 57.092,86.317 57.476,86.701 C58.271,87.495 57.421,88.456 57.312,88.566 L49.552,96.326 C49.442,96.434 48.484,97.285 47.688,96.488 C47.303,96.107 47.303,95.612 47.551,95.146 C48.208,93.884 48.017,93.748 47.002,92.734 L29.564,75.295 Z M47.852,69.784 C47.962,69.893 48.564,70.66 47.743,71.484 C46.974,72.251 46.124,71.675 45.44,71.155 C43.739,69.948 41.546,68.522 39.105,68.933 L37.515,67.343 L41.465,63.397 L47.852,69.784 Z M24.382,80.477 L28.331,76.529 L29.92,78.119 C29.509,80.559 30.935,82.752 32.141,84.453 C32.662,85.139 33.238,85.989 32.471,86.756 C31.648,87.58 30.88,86.976 30.77,86.864 L24.382,80.477 L24.382,80.477 Z"
      id="Fill-15"
      fill="#FFFFFE"
    />
    <path
      d="M67.977,71.759 C68.991,72.773 69.128,72.964 70.389,72.307 C70.856,72.061 71.349,72.061 71.733,72.443 C72.528,73.238 71.677,74.199 71.568,74.308 L63.809,82.067 C63.699,82.177 62.739,83.027 61.944,82.233 C61.562,81.848 61.561,81.355 61.808,80.888 C62.466,79.628 62.274,79.49 61.26,78.476 L46.893,64.109 C45.878,63.094 45.741,62.902 44.479,63.56 C44.012,63.807 43.519,63.807 43.136,63.423 C42.341,62.628 43.191,61.668 43.3,61.559 L51.06,53.798 C51.17,53.69 52.129,52.839 52.924,53.635 C53.309,54.019 53.309,54.513 53.06,54.979 C52.404,56.239 52.595,56.377 53.61,57.391 L67.977,71.759 Z M65.071,61.559 L62.658,63.971 L61.067,62.381 L63.48,59.968 L57.256,53.745 C56.242,52.731 56.104,52.538 54.844,53.196 C54.378,53.443 53.885,53.443 53.5,53.059 C52.705,52.264 53.555,51.305 53.665,51.194 L61.423,43.436 C61.534,43.326 62.494,42.476 63.289,43.271 C63.672,43.655 63.672,44.148 63.426,44.615 C62.768,45.875 62.96,46.013 63.974,47.027 L78.341,61.395 C79.356,62.409 79.493,62.6 80.754,61.943 C81.22,61.696 81.713,61.696 82.097,62.08 C82.893,62.875 82.043,63.834 81.933,63.944 L74.174,71.704 C74.064,71.814 73.104,72.663 72.308,71.867 C71.926,71.485 71.926,70.991 72.172,70.524 C72.829,69.263 72.638,69.126 71.624,68.112 L65.071,61.559 L65.071,61.559 Z"
      id="Fill-16"
      fill="#FFFFFE"
    />
    <path
      d="M64.008,40.851 L71.247,33.613 L91.756,54.121 L84.518,61.36 C84.408,61.47 83.449,62.319 82.653,61.524 C82.269,61.141 82.269,60.647 82.515,60.181 C83.174,58.919 82.982,58.782 81.967,57.768 L67.6,43.4 C66.586,42.386 66.448,42.193 65.187,42.852 C64.721,43.1 64.227,43.1 63.844,42.716 C63.049,41.92 63.899,40.961 64.008,40.851 Z M72.481,32.379 L78.595,26.266 L84.161,31.831 C84.298,31.968 84.791,32.515 83.969,33.338 C83.502,33.804 83.091,33.667 82.544,33.393 C79.609,31.886 76.867,31.939 74.071,33.968 L72.481,32.379 Z M91.399,51.297 C93.346,48.802 93.538,45.595 92.085,42.661 C91.865,42.057 91.591,41.454 92.085,40.961 C92.962,40.083 93.702,40.988 93.811,41.098 L99.295,46.581 L92.99,52.887 L91.399,51.297 Z M81.639,41.535 C82.488,40.083 81.94,38.493 81.145,37.204 C80.816,36.601 80.542,35.998 81.117,35.422 C81.967,34.572 82.817,35.696 83.036,35.916 L88.959,41.838 C89.179,42.057 90.302,42.908 89.453,43.757 C88.877,44.333 88.273,44.059 87.67,43.729 C86.381,42.935 84.791,42.386 83.338,43.237 L81.639,41.535 L81.639,41.535 Z"
      id="Fill-17"
      fill="#FFFFFE"
    />
  </svg>
);

const ThdIcon = ({ size }) => (
  <Icon component={ThdSvg} style={{ fontSize: size }} />
);

export default ThdIcon;
