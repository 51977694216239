import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import * as userProfile from '../redux/selectors/userProfile';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import PvendorSearch from './PvendorSearch';
import PvendorInfo from './PvendorInfo';
import MvendorInfo from './MvendorInfo';
import ContactsInfo from './ContactsInfo';
import './Main.scss';

const Main = () => {
  const isUserInternal = useSelector(userProfile.isUserInternal);
  const pvendorNumber = useSelector(userProfile.getPvendorNumber);
  const isPvendorActive = useSelector(userSelectedVariables.isPvendorActive);
  const selectedMvendor = useSelector(userSelectedVariables.getSelectedMvendor);

  return (
    <>
      <div className="title-container">
        <h1 className="title">Store Contacts</h1>
      </div>
      {isUserInternal && (
        <>
          <PvendorSearch />
          <Divider />
        </>
      )}
      {isPvendorActive ? (
        <>
          <PvendorInfo />
          <Divider />
          <MvendorInfo />
          {selectedMvendor && (
            <>
              <Divider />
              <ContactsInfo />
            </>
          )}
        </>
      ) : (
        !isUserInternal && !pvendorNumber && (
          <div>
            Your Login ID is currently not associated with an existing Vendor
          </div>
        )
      )}
    </>
  );
};

export default Main;
