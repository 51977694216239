import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import DeleteContactModalContent from './DeleteContactModalContent';
import { modalClosed } from '../redux/actions';
import './ContactModal.scss';

const DeleteContactModal = ({ modalVisibility }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      visible={modalVisibility}
      destroyOnClose
      centered
      maskClosable
      zIndex={999}
      className="contact-modal no-footer-border-top"
      keyboard
      footer={null}
      onCancel={() => {
        dispatch(modalClosed());
      }}
      wrapProps={{
        'data-testid': 'delete-modal',
      }}
    >
      <DeleteContactModalContent />
    </Modal>
  );
};

export default DeleteContactModal;
