import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import * as selectors from '../redux/selectors';
import { getSelectedMvendorContactsAssignedToByos, getSelectedMvendorContactsAssignedToMarkets } from '../redux/selectors/userSelectedVariables';
import EditIcon from '../components/EditIcon';
import DeleteIcon from '../components/DeleteIcon';

const MarketContactsTable = ({ showEditColumns, onEditIconClick, onDeleteIconClick }) => {
  const dataSource = useSelector(selectors.getByoMarketTableData);
  const byoContactList = useSelector(getSelectedMvendorContactsAssignedToByos);
  const marketContactList = useSelector(getSelectedMvendorContactsAssignedToMarkets);
  const contacts = _.concat(byoContactList, marketContactList);

  const onIconClick = (callback, record) => {
    callback(
      _.find(contacts, { addressNumber: record.id }),
      record.byoNumber ? { byos: [record.byoNumber] } : { markets: [record.marketNumber] }
    );
  };

  const editUserColumns = [
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      width: '5%',
      className: 'icon-column',
      render: (text, record) => (
        record.id ? <EditIcon onClick={() => onIconClick(onEditIconClick, record)} /> : null
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      width: '5%',
      className: 'icon-column',
      render: (text, record) => (
        record.id ? <DeleteIcon onClick={() => onIconClick(onDeleteIconClick, record)} /> : null
      ),
    },
  ];

  const columns = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: showEditColumns ? '28%' : '30%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: showEditColumns ? '13%' : '15%',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: showEditColumns ? '13%' : '15%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: showEditColumns ? '18%' : '20%',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: showEditColumns ? '18%' : '20%',
    },
    ...(showEditColumns ? editUserColumns : []),
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      bordered
    />
  );
};

export default MarketContactsTable;
