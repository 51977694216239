import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import initialState from './initialState';
import rootReducer from './reducers';

const loggerMiddleware = createLogger({ collapsed: true });

const getMiddleware = () => {
  const middleware = [thunkMiddleware];

  if (process.env.REACT_APP_ENABLE_LOGS) {
    middleware.push(loggerMiddleware);
  }
  return middleware;
};

const composeEnhancers = composeWithDevTools(
  applyMiddleware(...getMiddleware())
);

const configureStore = (preloadedState) =>
  createStore(rootReducer, preloadedState, composeEnhancers);

export default function getStore(state = initialState) {
  return configureStore(state);
}
