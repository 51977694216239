import { fetchErrorHandler } from '../util';

const deleteSession = () =>
  fetch('/api/users/session', { method: 'DELETE' }).then(
    fetchErrorHandler('Error clearing session')
  );

const getEnvironment = () =>
  fetch('/api/environment')
    .then(fetchErrorHandler('Unable to determine environment'))
    .then((res) => res.json());

const getUserProfile = () =>
  fetch('/api/users/session')
    .then(fetchErrorHandler('Unable to validate session'))
    .then((res) => res.json());

const getPvendor = (pvendorNumber) =>
  fetch(`/api/pvendors/${pvendorNumber}`)
    .then(fetchErrorHandler('Unable to retrieve Pvendor data'))
    .then((res) => res.json());

const getVendorTypes = () =>
  fetch('/api/vendorTypes')
    .then(fetchErrorHandler('Unable to retrieve vendor types'))
    .then((res) => res.json());

const getVendorSubTypes = () =>
  fetch('/api/vendorSubTypes')
    .then(fetchErrorHandler('Unable to retrieve Vendor SubTypes'))
    .then((res) => res.json());

const getByos = () =>
  fetch('/api/locations/byos')
    .then(fetchErrorHandler('Unable to retrieve BYOs'))
    .then((res) => res.json());

const getDcs = () =>
  fetch('/api/locations/dcs')
    .then(fetchErrorHandler('Unable to retrieve DCs'))
    .then((res) => res.json());

const getCountries = () =>
  fetch('api/locations/geographic/countries')
    .then(fetchErrorHandler('Unable to retrieve Countries'))
    .then((res) => res.json());

const getCountryStates = (countryCode) =>
  fetch(`api/locations/geographic/countries/${countryCode}/states`)
    .then(fetchErrorHandler('Unable to retrieve States'))
    .then((res) => res.json());

const addContact = (pvendorNumber, mvendorNumber, departmentNumber, contact) =>
  fetch(`/api/pvendors/${pvendorNumber}/mvendors/${mvendorNumber}/${departmentNumber}/contacts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contact),
  })
    .then(fetchErrorHandler('Unable to save new contact'));

const updateContact = (pvendorNumber, mvendorNumber, departmentNumber, contact) =>
  fetch(`/api/pvendors/${pvendorNumber}/mvendors/${mvendorNumber}/${departmentNumber}/contacts`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contact),
  })
    .then(fetchErrorHandler('Unable to update contact'));

const deleteContactAssignments = (
  pvendorNumber,
  mvendorNumber,
  departmentNumber,
  contact,
  deleteAll
) =>
  fetch(`/api/pvendors/${pvendorNumber}/mvendors/${mvendorNumber}/${departmentNumber}/contacts`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'delete-all': deleteAll,
    },
    body: JSON.stringify(contact),
  })
    .then(fetchErrorHandler('Unable to delete assignment'));

const api = {
  deleteSession,
  getEnvironment,
  getUserProfile,
  getPvendor,
  getVendorTypes,
  getVendorSubTypes,
  getByos,
  getDcs,
  getCountries,
  getCountryStates,
  addContact,
  updateContact,
  deleteContactAssignments,
};

export default api;
