import _ from 'lodash';
import fp from 'lodash/fp';
import { createSelector } from 'reselect';

export const getVendorSubTypes = fp.get('constants.vendorSubTypes');
export const getVendorTypes = fp.get('constants.vendorTypes');
export const getByos = fp.get('constants.byos');
export const getDcs = fp.get('constants.dcs');
export const getCountries = fp.get('constants.countries');

export const getMarkets = createSelector(getByos, fp.flatMap('markets'));

export const getByoForMarketFunction = createSelector(getByos, (byos) =>
  _.memoize((marketNbr) =>
    _.find(byos, (byo) => !_.isUndefined(_.find(byo.markets, { marketNbr })))));

export const generateCountryOptions = createSelector(
  getCountries,
  (countries) =>
    fp.chain(countries)
      .map((country) => (
        {
          value: country.code,
          label: country.name,
        }))
      .sortBy(({ value }) => (value === 'US' ? 0 : 1))
      .value()
);

const generateContactAssignmentByoMarketEntries = (market) => ({
  key: market.marketNbr,
  label: `${market.marketNbr} - ${market.marketDesc}`,
});

export const getContactAssignmentByoMarketTableEntries = createSelector(
  getByos,
  (byos) =>
    _.chain(byos)
      .keyBy('byoNbr')
      .mapValues(fp.get('markets'))
      .mapValues(fp.map(generateContactAssignmentByoMarketEntries))
      .value()
);

const generateContactAssignmentByoTableEntry = (byo) => ({
  key: byo.byoNbr,
  label: `${byo.byoNbr} - ${byo.byoDesc}`,
});

export const getContactAssignmentByoTableEntries = createSelector(
  getByos,
  fp.map(generateContactAssignmentByoTableEntry)
);

const generateContactAssignmentDcTableEntry = (dc) => ({
  key: dc.distributionCenterNumber,
  label: `${dc.distributionCenterNumber} - ${dc.distributionCenterName}`,
});

export const getContactAssignmentDcTableEntries = createSelector(
  getDcs,
  (dcs) =>
    dcs.filter((dc) => !_.isEqual(dc.distributionCenterTypeIndicator, 'R'))
      .map(generateContactAssignmentDcTableEntry)
);
