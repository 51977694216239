import React from 'react';
import { Tabs, Divider } from 'antd';
import ContactAssignmentCorporateTable from './ContactAssignmentCorporateTable';
import ContactAssignmentDcTable from './ContactAssignmentDcTable';
import ContactAssignmentByoMarketTable from './ContactAssignmentByoMarketTable';
import './ContactAssignmentInfo.scss';

const { TabPane } = Tabs;

const ContactAssignmentInfo = () => (
  <>
    <div data-testid="border-highlight" className="contact-assignment-tabs section margin-left">
      <Tabs defaultActiveKey="corporate">
        <TabPane tab="Corporate" key="corporate">
          <div>
            <Divider />
            <ContactAssignmentCorporateTable />
          </div>
        </TabPane>
        <TabPane tab="BYO" key="byo">
          <div>
            <ContactAssignmentByoMarketTable byoCheckbox />
          </div>
        </TabPane>
        <TabPane tab="Market" key="market">
          <div>
            <ContactAssignmentByoMarketTable marketCheckbox />
          </div>
        </TabPane>
        <TabPane tab="DC" key="dc">
          <div>
            <ContactAssignmentDcTable />
          </div>
        </TabPane>
      </Tabs>
    </div>
  </>
);

export default ContactAssignmentInfo;
