import React from 'react';
import './ResultItem.scss';

const ResultItem = ({ header, children }) => (
  <div className="results-item">
    {header}
    <div className="results-data" data-testid="value">
      {children}
    </div>
  </div>
);

export default ResultItem;
