import React from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as selectors from '../redux/selectors/userSelectedVariables';
import EditIcon from '../components/EditIcon';

const CorporateContacts = ({ showEditColumns, onEditIconClick }) => {
  const dataSource = useSelector(selectors.getCorporateTableData);
  const corporateContactList = useSelector(selectors.getSelectedMvendorCorporateContacts);

  const editUserColumns = [
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      width: '5%',
      className: 'icon-column',
      render: (text, record) => (
        <EditIcon
          onClick={() => {
            onEditIconClick(
              _.find(corporateContactList, { addressNumber: record.id }),
              { companies: ['1'] }
            );
          }}
        />
      ),
    },
  ];

  const columns = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: showEditColumns ? '14%' : '15%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: showEditColumns ? '14%' : '15%',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: showEditColumns ? '14%' : '15%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: showEditColumns ? '19%' : '20%',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: showEditColumns ? '34%' : '35%',
    },
    ...(showEditColumns ? editUserColumns : []),
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      bordered
    />
  );
};

export default CorporateContacts;
