import React, { useState, useEffect } from 'react';
import { Steps, Button, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import ContactForm from './ContactForm';
import Summary from './Summary';
import ContactAssignment from './ContactAssignment';
import * as userSelectedVariables from '../redux/selectors/userSelectedVariables';
import { clearError, displayError } from '../redux/actions';
import './ContactStepper.scss';

const ContactStepper = ({ onDone, modalType }) => {
  const dispatch = useDispatch();
  const contact = useSelector(userSelectedVariables.getContact);
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);

  const hasLocation = _.curry((location, newContact) =>
    !_.isEmpty(_.get(newContact, location, [])));

  const hasAssignments = _.overSome([hasLocation('companies'),
    hasLocation('byos'),
    hasLocation('markets'),
    hasLocation('dcs')])(contact);

  useEffect(() => hasAssignments && dispatch(clearError('ASSIGNMENT_ERROR')), [hasAssignments, dispatch]);

  const next = () => {
    setCurrent(current + 1);
  };

  const validateAssignment = () => {
    if (hasAssignments) {
      next();
    } else {
      dispatch(displayError({ code: 'ASSIGNMENT_ERROR', message: 'A contact must be assigned to at least one location before moving to the next step.' }));
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: 'Profile',
      content: <ContactForm next={next} modalType={modalType} />,
    },
    ...(modalType === 'ADD'
      ? [{
        title: 'Contact Assignment',
        content: <ContactAssignment />,
      }] : []
    ),
    {
      title: 'Summary',
      content: <Summary />,
    },
  ];

  return (
    <>
      <Steps current={current} size="small" className="steps--width">
        {steps.map((item) => (
          <Step data-testid="contact-steps" key={item.title} title={item.title} />
        ))}
      </Steps>
      <Divider />
      { steps[current].content }
      {current > 0 && <Divider />}
      <div className="steps-action text-centered">
        {current > 0 && (
        <Button className="footer-button" onClick={() => prev()}>
          Back
        </Button>
        )}
        {current > 0 && !(current === steps.length - 1) && (
        <Button className="footer-button footer-button--right" type="primary" onClick={() => validateAssignment()}>
          Next Step
        </Button>
        )}
        {current === steps.length - 1 && (
        <Button className="footer-button footer-button--right" type="primary" onClick={onDone}>
          Save
        </Button>
        )}
      </div>
    </>
  );
};

export default ContactStepper;
