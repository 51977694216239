import React from 'react';
import ContactAssignmentInfo from './ContactAssignmentInfo';
import './ContactAssignment.scss';

const ContactAssignment = () => (
  <div>
    <h3 className="bold"> Contact Assignment </h3>
    <h4 className="bold contact-assignment-subheader"> Which locations should this person be assigned to? </h4>
    <p className="required contact-assignment-paragraph"> The selections you make here will overwrite any of the current selections you have assigned </p>
    <p className="contact-assignment-paragraph"> Buying Office and Market contacts are sent to the stores. </p>
    <ContactAssignmentInfo />
  </div>
);

export default ContactAssignment;
